import Utils from 'utils';
import setHideMailAlertBannerCookie from 'utils/set-mail-alert-banner-cookie';

const MailAlertBannerForm = (() => {
  const ATTRIBUTES = {
    'close' : 'data-banner-close',
  };

  const SELECTORS = {
    'close': `[${ATTRIBUTES.close}]`,
    'email': 'mail_alert_from_search_inline_email',
  };

  const FORMS = {
    'create': 'mail_alert_form_inline_create',
    'success': 'mail_alert_form_inline_success',
  };

  class MailAlertBannerForm {
    constructor(options = {}) {
      this.unbinders = [];
      this.closeBtn = null;
      this.firstInit = true;

      this.init();
    }

    init() {
      if (Utils.isMobile()) {
        return;
      }

      setTimeout(() => {
        document.getElementById(SELECTORS.email).focus();
      }, 0);

      if (true === this.firstInit) {
        this.firstInit = false;
      }

      this.closeBtn = document.querySelector(SELECTORS.close);
      this.addListeners();
    }

    close() {
      Utils.remove(this.closeBtn.parentElement);
    }

    addListeners() {
      const closeBtnHandler = (e) => {
        setHideMailAlertBannerCookie();
        this.close();
      };

      const closeBtnHandlerUnbinder = () => {
        this.closeBtn.removeEventListener('click', closeBtnHandler);
      }
      this.unbinders.push(closeBtnHandlerUnbinder);
      this.closeBtn.addEventListener('click', closeBtnHandler);

      const formSuccessHandler = (data) => {
        if (data.name == FORMS['create']) {
          this.dispose();
          this.init();
          return;
        }

        if (data.name == FORMS['success']) {
          setHideMailAlertBannerCookie();
          this.dispose();
          return;
        }
      }

      const formSuccessHandlerUnbinder = () => {
        HW.app.events.off('form:success', formSuccessHandler);
      }
      HW.app.events.on('form:success', formSuccessHandler);
      this.unbinders.push(formSuccessHandlerUnbinder);
    }

    dispose() {
      this.unbinders.map((unbinder) => {
        unbinder();
      });
    }
  }

  return MailAlertBannerForm;
})();

module.exports = MailAlertBannerForm;
