import Utils from 'utils'

const FormSelect = (() => {
  const CLASSES = {
    'active': 'active',
    'focus': 'focus',
    'error': 'error',
    'controlBlock': 'control-group',
    'controlElement': '__control',
    'labelElement': '__label',
    'controlModifier': '--select',
  }
  const SELECTORS = {
    'select': '[data-form-control="select"]',
    'default': '[data-select-default]',
    'containerSuffix': '_container',
  }
  const LABELS = {
    'resetSelection': HW.i18n.form.reset_selection
  }
  const ATTRIBUTES = {
    'select': 'select',
    'dataControl': 'data-form-control',
    'dataDefault': 'data-select-default',
  }

  class FormSelect {
    constructor(options = {}) {
      this.el             = options.el

      this.form           = options.form

      this.options        = options.options
      this.id             = options.id
      this.label          = options.label
      this.container      = options.container
      this.extraLabel     = options.extra_label || null
      this.isSelected     = false

      this.parentEl       = null
      this.defaultOption  = null

      this.init()
    }
    init = () => {
      if (!this.el) {
        this.render()
      }
      this.parentEl = this.el.parentElement
      this.label = this.parentEl.querySelector('label')
      this.defaultOption = this.el.querySelector(SELECTORS.default)
      this.updateLabel()
      this.addListeners()
    }
    render() {
      // if no el provided, create one
      // needs container, id, options and label
      this.el = document.createElement('select')
      let selectDefaultOption = document.createElement('option')
      let selectContainer = document.createElement('div')
      let selectLabel = document.createElement('label')

      this.el.setAttribute(ATTRIBUTES.dataControl, ATTRIBUTES.select)
      this.el.id = this.id
      selectLabel.innerHTML = this.label
      selectDefaultOption.innerHTML = `Type ${this.label}`
      selectDefaultOption.selected = 'selected'
      selectDefaultOption.setAttribute(ATTRIBUTES.dataDefault, '')
      selectDefaultOption.value = ''
      selectContainer.id = `${this.id}${SELECTORS.containerSuffix}`
      Utils.addClass(selectContainer, [CLASSES.controlBlock, `${CLASSES.controlBlock}${CLASSES.controlModifier}`])
      Utils.addClass(this.el, `${CLASSES.controlBlock}${CLASSES.controlElement}`)
      Utils.addClass(selectLabel, `${CLASSES.controlBlock}${CLASSES.labelElement}`)
      selectContainer.appendChild(selectLabel)
      selectContainer.appendChild(this.el)

      if (this.extraLabel) {
        let extraLabel = document.createElement('span')
        Utils.addClass(extraLabel, 'control-group__validation')
        extraLabel.innerHTML = this.extraLabel
        selectContainer.appendChild(extraLabel)
      }

      this.el.appendChild(selectDefaultOption)

      Utils.forEach(this.options, (el) => {
        let option = el.cloneNode(true)
        this.el.appendChild(option)
        if (option.selected) {
          this.isSelected = true
        }
      })
      this.container.appendChild(selectContainer)
    }
    addListeners() {
      this.el.addEventListener('focus', this.focus)
      this.el.addEventListener('blur', this.unfocus)
      this.el.addEventListener('change', this.changeHandler)
    }
    removeListeners() {
      this.el.removeEventListener('focus', this.focus)
      this.el.removeEventListener('blur', this.unfocus)
      this.el.removeEventListener('change', this.changeHandler)
      if (this.dropdown) {
        this.dropdown.dispose()
      }
    }
    focus = () => {
      Utils.addClass(this.parentEl, CLASSES.focus)
    }
    unfocus = () => {
      Utils.removeClass(this.parentEl, CLASSES.focus)
    }
    changeHandler = () => {
      if (this.el.value != '') {
        Utils.removeClass(this.parentEl, CLASSES.error)
      }
      this.updateLabel()
      if (this.triggerAsync) {
        Utils.addClass(this.parentEl, 'fetching')
        this.form.submitHandler()
        this.el.disabled = true
      }
    }
    updateLabel() {
      if (this.el.value != "") {
        Utils.addClass(this.parentEl, CLASSES.active)
        if (this.defaultOption) {
          this.defaultOption.innerHTML = LABELS.resetSelection
        }
      } else {
        Utils.removeClass(this.parentEl, CLASSES.active)
        if (this.defaultOption) {
          this.defaultOption.innerHTML = this.label.innerHTML
        }
      }
    }
    dispose = () => {
      this.removeListeners()
      this.el = null
    }
    get triggerAsync() {
      return this.form && this.el.hasAttribute(this.form.asyncTrigger)
    }
    static getSelector() {
      return SELECTORS.select
    }
  }

  return FormSelect
})()

module.exports = FormSelect
