import FormModal from 'utils/form-modal'
import Utils from 'utils'

const LoginModal = (() => {
  class LoginModal extends FormModal {
    transformBody(body) {
      let uri = Utils.getRequestURI();

      return `${body}&login%5B_target_path%5D=${uri}`
    }
  }

  return LoginModal
})()

export default LoginModal
