const MapMarkerData = (() => {
    const IMAGES = {
        'cluster': '/images/maps/map-marker--stack',
        'marker': '/images/maps/map-marker.png',
        'active': '/images/maps/map-marker--active.png',
    };

    return {
        'getForProperty': (active) => {
            return {
                'image': {
                    'url': active ? IMAGES.active : IMAGES.marker,
                    'size': new google.maps.Size(31, 34),
                    'scaledSize': new google.maps.Size(31, 34),
                    'origin': new google.maps.Point(0, 0),
                    'anchor': new google.maps.Point(18, 18),
                    'optimized': true,
                },
                'shape': {
                    'type': 'rect',
                    'coord': [ 0, 0, 31, 34 ],
                }
            }
        },
        'getForCluster': () => {
            return {
                'image': IMAGES.cluster,
                'extension': 'png',
                'sizes': [34]
            }
        }
    }
})();

module.exports = MapMarkerData;
