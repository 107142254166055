import Utils from 'utils';

const Toggle = (() => {
  const ATTRIBUTES = {
    'toggle': 'data-toggle',
  }

  const MODIFIERS = {
    'active': '--active',
  }

  const SELECTORS = {
    'toggle': `[${ATTRIBUTES.toggle}]`,
  };

  class Toggle {
    constructor(options={}) {
      this.el = options.el;

      this.init();
    }
    init() {
      this.el.addEventListener('click', this.toggle);
      document.body.addEventListener('click', this.bodyClick);
    }
    bodyClick = (e) => {
      if (!this.isActive) {
        return;
      }
      let target = e.target;

      if (target !== this.el && !Utils.closestParent(target, this.el)) {
        this.toggle();
      }
    }
    toggle = () => {
      Utils.toggle(this.parent, this.toggleActiveClass);
    }
    dispose() {
      Utils.removeClass(this.parent, this.toggleActiveClass);
    }
    get name() {
      return this.el.getAttribute(ATTRIBUTES.toggle);
    }
    get toggleClass() {
      return `${this.name}`;
    }
    get toggleActiveClass() {
      return `${this.toggleClass}${MODIFIERS.active}`;
    }
    get parent() {
      return this.el.parentElement;
    }
    get isActive() {
      return Utils.hasClass(this.parent, this.toggleActiveClass);
    }
    static getSelector() {
      return SELECTORS.toggle;
    }
  }

  return Toggle;
})();

module.exports = Toggle;
