import Utils from 'utils'

const FormChoice = (() => {
  const CLASSES = {
    'active': 'active',
    'focus': 'focus',
    'error': 'error',
    'controlBlock': 'choice',
    'controlElement': '__control',
    'labelElement': '__label',
    'choiceButton': 'choice-buttons__button',
  }
  const SELECTORS = {
    'choice': '[data-form-control="choice"]',
    'containerSuffix': '_container',
  }
  const ATTRIBUTES = {
    'choice': 'choice',
    'dataControl': 'data-form-control',
  }

  class FormChoice {
    constructor(options = {}) {
      this.el             = options.el

      this.form           = options.form

      this.id             = options.id
      this.name           = options.name
      this.label          = options.label
      this.value          = options.value
      this.container      = options.container
      this.type           = options.type
      this.modifier       = options.modifier
      this.checked        = options.checked || false

      this.parentEl       = null

      this.init()
    }
    init = () => {
      if (!this.el) {
        this.render()
      }

      this.parentEl = this.el.parentElement

      if (!this.container) {
        // get control group of choices
        this.container = this.parentEl.parentElement
      }

      this.addListeners()
    }
    addListeners() {
      this.el.addEventListener('change', this.changeHandler)
    }
    removeListeners() {
      this.el.removeEventListener('change', this.changeHandler)
    }
    changeHandler = () => {
      Utils.removeClass(this.container, CLASSES.error)
      if (this.triggerAsync) {
        Utils.addClass(this.parentEl, 'fetching')
        this.form.submitHandler()
        this.el.disabled = true
      }
    }
    render() {
      // if no el provided, create one
      // needs container, id, name, type and label
      this.el = document.createElement('input')
      let choiceContainer = document.createElement('div')
      let choiceLabel = document.createElement('label')

      this.el.setAttribute(ATTRIBUTES.dataControl, this.type)
      this.el.id = this.id
      this.el.name = this.name
      this.el.type = this.type
      this.el.checked = this.checked
      this.el.value = this.value

      choiceLabel.innerHTML = this.label
      choiceLabel.setAttribute('for', this.id)
      Utils.addClass(choiceContainer, this.containerClasses)
      Utils.addClass(this.el, `${CLASSES.controlBlock}${CLASSES.controlElement}`)
      Utils.addClass(choiceLabel, `${CLASSES.controlBlock}${CLASSES.labelElement}`)
      choiceContainer.appendChild(this.el)
      choiceContainer.appendChild(choiceLabel)

      this.container.appendChild(choiceContainer)
    }
    dispose = () => {
      this.removeListeners()
      this.el = null
    }
    get triggerAsync() {
      return this.form && this.el.hasAttribute(this.form.asyncTrigger)
    }
    get containerClasses() {
      let classes = [CLASSES.controlBlock];
      if (this.modifier == 'button') {
        classes.push(`${CLASSES.controlBlock}--${this.modifier}`, CLASSES.choiceButton);
      } else {
        classes.push(`${CLASSES.controlBlock}--${this.type}`);
      }
      return classes;
    }
    static getSelector() {
      return SELECTORS.choice
    }
  }

  return FormChoice
})()

module.exports = FormChoice
