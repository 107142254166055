export let Utils = {
  removeClass(el, className) {
    if (typeof className == 'string') {
      className = [className]
    }
    className.map(function(name) {
      if (el.classList) {
        el.classList.remove(name)
      } else {
        el.className = el.className.replace(new RegExp('(^|\\b)' + name.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
      }
    })
  },
  addClass(el, className) {
    if (typeof className == 'string') {
      className = [className]
    }
    className.map(function(name) {
      if (el.classList) {
        el.classList.add(name)
      } else {
        el.className += ' ' + name
      }
    })
  },
  hasClass(el, className) {
    if (el.classList) {
      return el.classList.contains(className);
    } else {
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className)
    }
  },
  toggle(el, className) {
    if (this.hasClass(el, className)) {
      this.removeClass(el, className)
    } else {
      this.addClass(el, className)
    }
  },
  forEach(array, callback, scope) {
    Array.prototype.forEach.call(array, callback, scope)
  },
  trigger(el, eventName, data = {}) {
    let event = null
    if (window.CustomEvent && typeof window.CustomEvent === 'function') {
      event = new CustomEvent(eventName, {detail: data})
    } else {
      event = document.createEvent('CustomEvent')
      event.initCustomEvent(eventName, true, true, data)
    }

    el.dispatchEvent(event)
  },
  ucfirst(str) {
    return str[0].toUpperCase() + str.slice(1);
  },
  text(el, str) {
    if ('textContent' in el) {
      el.textContent = str
    } else {
      el.innerText = str
    }
  },
  hide(el) {
    el.style.display = 'none'
  },
  show(el, style = 'block') {
    el.style.display = style
  },
  before(el, html) {
    // when html is documentFragment
    if (typeof html == 'object') {
      let div = document.createElement('div')
      div.appendChild(html.cloneNode(true))
      html = div.innerHTML
    }
    el.insertAdjacentHTML('beforebegin', html)
  },
  remove(el) {
    el.parentNode.removeChild(el)
  },
  append(el, html) {
    el.appendChild(html)
  },
  isHidden(el) {
    return el.offsetParent === null
  },
  isMobile(includeTablet = true) {
    if (!includeTablet) {
      return window.innerWidth < 768
    }

    return window.innerWidth < 992
  },
  getScrollY() {
    return window.scrollY ||
           window.pageYOffset ||
           document.documentElement.scrollTop
  },
  getOffsetTop(el) {
    return el.getBoundingClientRect().top
  },
  getOffset(el) {
    let x = 0
    let y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        x += el.offsetLeft - el.scrollLeft
        y += el.offsetTop - el.scrollTop
        el = el.offsetParent
    }
    return { top: y, left: x }
  },
  noop() {
    return true
  },
  hasHistory() {
    return !!(window.history && history.pushState)
  },
  getRequestURI() {
    return `${location.pathname}${location.search}`
  },
  objectToQueryString(obj, prefix) {
    var str = [];
    for(var p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
        str.push(typeof v == "object" ? this.objectToQueryString(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }

    return str.join("&");
  },
  updateQueryString(key, value, url) {
    if (!url) url = window.location.href
    let re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
        hash

    if (re.test(url)) {
      if (typeof value !== 'undefined' && value !== null) {
        return url.replace(re, '$1' + key + "=" + value + '$2$3')
      } else {
        hash = url.split('#');
        url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '')
        if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
          url += '#' + hash[1]
        }
        return url
      }
    } else {
      if (typeof value !== 'undefined' && value !== null) {
        let separator = url.indexOf('?') !== -1 ? '&' : '?'
        hash = url.split('#')
        url = hash[0] + separator + key + '=' + value
        if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
          url += '#' + hash[1]
        }
        return url
      } else {
        return url
      }
    }
  },
  findPosY(el) {
    let curtop = 0
    if (el.offsetParent) {
      do {
        curtop += el.offsetTop
      } while (el = el.offsetParent)
      return [curtop]
    }
  },
  createEl(nodeType,className = null) {
    let el = document.createElement(nodeType)
    if (className) {
      this.addClass(el, className)
    }
    return el
  },
  matches(el, selector) {
    let matchesFn;

    // find vendor prefix
    ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
      if (typeof document.body[fn] == 'function') {
        matchesFn = fn;

        return true;
      }

      return false;
    })

    return el[matchesFn](selector)
  },
  closestParent(el, target) {
    let parent;

    // traverse parents
    while (el!==null) {
      parent = el.parentElement;

      if (parent!==null) {
        if ((typeof target === 'string' && Utils.matches(parent, target)) || target === parent) {
          return parent;
        }
      }

      el = parent;
    }

    return null;
  },
  siblingIndex(el) {
    let index = 0

    while(el.previousSibling) {
      index++
      el = el.previousSibling
    }

    return index
  },
  getPreviousSiblings(element) {
    let siblings = [];
    let sibling = element.previousElementSibling;

    while (sibling) {
      siblings.unshift(sibling);

      sibling = sibling.previousElementSibling;
    }

    return siblings;
  },
  urldecode(url) {
    return decodeURIComponent(url.replace(/\+/g, ' '));
  },
  getPrefixedCSSProperty(cssProperty, jsProperty) {
    let prefixes = ['webkit', 'moz', 'o', 'ms'];
    let retVal;

    function test (cssPropertyName, jsPropertyName) {
      const node = document.createElement('div');

      return jsPropertyName in node.style;
    }

    if (test(cssProperty, jsProperty)) {
      return {
        'js': jsProperty,
        'css': cssProperty,
      };
    }

    Utils.forEach(prefixes, (prefix) => {
      const prefixedJSProperty = prefix + Utils.ucfirst(jsProperty);
      const prefixedCSSProperty = `-${prefix}-${cssProperty}`;

      if (test(prefixedCSSProperty, prefixedJSProperty)) {
        retVal = {
          'js': prefixedJSProperty,
          'css': prefixedCSSProperty,
        };
      }
    });

    if (!retVal) {
      throw new Error('Cannot find matching prefix');
    }

    return retVal;
  },
  debounce(callback, timeout) {
    let lastRun = null;

    return function (...args) {
      const now = Date.now();

      if (lastRun == null || lastRun + timeout < now) {
        lastRun = now;
        callback.apply(this, args);
      }
    }
  }
}

module.exports = Utils;
