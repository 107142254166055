import loadGoogleMaps from 'utils/google-maps-loader';
import AsyncScriptLoader from 'utils/async-script-loader';
import MapMarkerData from 'utils/map-marker-data';
import Utils from 'utils';
import {MessageBus} from 'helpers/message-bus/message-bus';

const Map = (() => {

    const SCRIPTS = {
        'markerClusterer': HW.resources.map,
    };

    const DEFAULTS = {
        'lat': 52.09132,
        'lng': 5.116882,
    };

    const MESSAGE_HANDLERS = [];

    const NAME = 'map';

    const styles = {
        hide: [
            {
                'featureType': 'administrative.land_parcel',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'administrative.neighborhood',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'poi.business',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'road',
                'elementType': 'labels.icon',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'transit',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                'featureType': 'water',
                'elementType': 'labels.text',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            }
        ]
    };

    class Map {
        constructor (options = {}) {
            this.element = options.element;
            this.searchClass = options.search || null;
            this.callback = options.callback || Utils.noop;

            this.map = null;
            this.markerClusterer = null;
            this.bounds = null;
            this.activeProperty = null;
            this.fitToBounds = true;
            this.firstInit = true;

            this.markers = [];
            this.markersCache = {};
            this.properties = [];

            this.messageBus = new MessageBus();
            this.messageBus.connect(this);

            // auto initialize when map is loaded
            this.loadMap();
        }

        loadMap (lat, lng, zoom) {
            loadGoogleMaps((google) => {
                const opts = {
                    center: new google.maps.LatLng(DEFAULTS.lat, DEFAULTS.lng),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    scaleControl: true,
                    rotateControl: true,
                    overviewMapControl: true,
                    streetViewControl: false,
                    gestureHandling: 'greedy',
                    fullscreenControl: false,
                    controlSize: 26,
                    zoomControl: !Utils.isMobile(), // disable zoom controls on mobile. Zooming is done by gesture
                };

                this.map = new google.maps.Map(this.element, opts);

                this.map.setOptions({
                    styles: styles['hide']
                });

                this.init();
            })
        }

        init () {
            this.loadClusterer();

            this.infoWindow = new google.maps.InfoWindow();

            google.maps.event.addDomListener(window, 'resize', this.resize);
            google.maps.event.addListener(this.map, 'click', () => {
                this.mapClick();
            });

            google.maps.event.addListener(this.map, 'dragend', this.search);
            google.maps.event.addListener(this.map, 'zoom_changed', this.search);

            this.callback();
        }

        search = (callback) => {
            if (this.firstInit) {
                this.firstInit = false;
                return;
            }

            this.searchClass.setGeoFields(this.map.getBounds().toUrlValue(), this.map.getZoom());
            this.searchClass.update();
        };

        loadClusterer () {
            let clusterMarkerData = MapMarkerData.getForCluster();
            let clusterOpts = {
                averageCenter: true,
                imagePath: clusterMarkerData.image,
                imageExtension: clusterMarkerData.extension,
                imageSizes: clusterMarkerData.sizes,
                maxZoom: 16,
                minimumClusterSize: 5,
                enableRetinaIcons: true,
                calculator: (markers, numStyles) => {
                    let res = MarkerClusterer.CALCULATOR(markers, numStyles);
                    res.text = `<span class='label label--cluster'>${res.text}</span>`;
                    return res;
                }
            };

            new AsyncScriptLoader(SCRIPTS.markerClusterer, () => {
                this.markerClusterer = new MarkerClusterer(this.map, [], clusterOpts);
            }, true);
        }

        hasMarkers () {
            return this.markers.length > 0;
        }

        clearMap () {
            if (!this.hasMarkers()) {
                return;
            }

            this.markerClusterer.clearMarkers();
            this.markers = [];
        }

        displayProperties (properties) {
            const propertyMarkerData = MapMarkerData.getForProperty(false);

            this.clearMap();
            this.setProperties(properties);
            this.bounds = new google.maps.LatLngBounds();

            this.properties.map((property) => {
                let latLng = (property.lat && property.lng) ? new google.maps.LatLng(property.lat, property.lng) : null;
                let marker;

                if (this.markersCache[property.id] === undefined) {
                    marker = new google.maps.Marker({
                        position: latLng,
                        icon: propertyMarkerData.image,
                        shape: propertyMarkerData.shape
                    });

                    this.markersCache[property.id] = marker;
                } else {
                    marker = this.markersCache[property.id];
                }

                property.marker = marker;
                marker.id = property.id;

                // open info window on marker click
                marker.addListener('click', () => {
                    this.resetActiveProperty();

                    this.setActiveProperty(marker);

                    this.messageBus.postMessage({
                        'message': 'mapMarkerClicked',
                        'data': {
                            'id': property.id,
                        }
                    }, {
                        'name': this.componentName,
                        'scope': 'all',
                    });
                });
                this.markers.push(marker);
                this.bounds.extend(latLng);
            });

            this.markerClusterer.addMarkers(this.markers);

            if (this.fitToBounds) {
                this.map.fitBounds(this.bounds);
                this.map.panTo(this.bounds.getCenter());

                this.fitToBounds = false;
            }
        }

        setProperties (properties) {
            this.properties = [];

            properties.map((property) => {
                let lat = property.lat;
                let lng = property.lng;

                if (!lat && !lng) {
                    return;
                }

                this.properties.push({
                    'id': property.id,
                    'lat': lat,
                    'lng': lng,
                });
            });
        }

        resize = () => {
            if (this.map) {
                google.maps.event.trigger(this.map, 'resize');
                if (this.bounds) {
                    this.map.fitBounds(this.bounds);
                    this.map.panToBounds(this.bounds);
                }
            }
        };

        mapClick = () => {
            this.resetActiveProperty();

            this.activeProperty = null;
            this.messageBus.postMessage({
                'message': 'mapClicked',
            }, {
                'name': this.componentName,
                'scope': 'all',
            });
        };

        setActiveProperty (marker) {
            const markerData = MapMarkerData.getForProperty(true);

            marker.setIcon(markerData.image);
            this.activeProperty = marker;
        }

        resetActiveProperty () {
            const propertyMarkerData = MapMarkerData.getForProperty(false);

            if (this.activeProperty !== null) {
                this.activeProperty.setIcon(propertyMarkerData.image);
            }
        }

        get componentName () {
            return NAME;
        }

        get messageHandlers () {
            return MESSAGE_HANDLERS;
        }
    }

    return Map;
})();

module.exports = Map;
