import Utils from 'utils';
import Fetch from 'utils/fetch';
import keycode from 'keycode';

const AutoComplete = (() => {
  const ATTRIBUTES = {
    'autocomplete': 'data-autocomplete',
    'value': 'data-value'
  };
  const BEM = {
    'dropdownBlock': 'dropdown',
    'menuElement': '__menu',
    'itemElement': '__item',
    'selectedModifier': '--selected',
    'recentSearchModifier': '--recent-search',
    'autocompleteModifier': '--autocomplete',
  };
  const CLASSES = {
    'open': 'open',
    'dropdown': BEM.dropdownBlock,
    'dropdownMenu': `${BEM.dropdownBlock}${BEM.menuElement}`,
    'dropdownItem': `${BEM.dropdownBlock}${BEM.itemElement}`,
    'dropdownItemSelected': `${BEM.dropdownBlock}${BEM.itemElement}${BEM.selectedModifier}`,
    'dropdownItemRecentSearch': `${BEM.dropdownBlock}${BEM.itemElement}${BEM.recentSearchModifier}`,
    'dropdownAutocomplete': `${BEM.dropdownBlock}${BEM.autocompleteModifier}`,
  };
  const SELECTORS = {
    'autocomplete': `[${ATTRIBUTES.autocomplete}]`,
    'dropdownItemSelected': `.${CLASSES.dropdownItemSelected}`,
  };

  class AutoComplete {
    constructor(options, callback = Utils.noop) {
      if (!options.el) {
        return;
      }

      this.el           = options.el;
      this.endpoint     = options.endpoint;
      this.key          = options.key;
      this.displayKey   = options.displayKey || 'title_long';
      this.callback     = callback;
      this.menu         = null;
      this.highlighted  = null;
      this.dropdown     = null;
      this.cancel       = false;
      this.opened       = false;
      this.params       = options.params || [];
      this.cachedQuery  = null;

      this.init();
    }
    init() {
      this.renderMenu();
      this.el.addEventListener('keydown', this.keydown);
      this.el.addEventListener('keyup', this.keyup);
      this.el.addEventListener('keypress', this.keypress);
      this.el.addEventListener('focus', this.focus);

      document.addEventListener('click', (e) => {
        if (this.opened && e.target !== this.el) {
          this.close();
        }
      });

      document.addEventListener('keyup', (e) => {
        if (keycode(e.which) == 'esc') {
          this.close();
        }
      });
    }
    renderMenu() {
      this.menu = Utils.createEl('ul', CLASSES.dropdownMenu);
      this.menu.addEventListener('click', this.select);
      this.dropdown = Utils.createEl('div', [CLASSES.dropdown, CLASSES.dropdownAutocomplete]);
      this.el.parentNode.appendChild(this.dropdown);
      this.dropdown.appendChild(this.menu);
    }
    keydown = (e) => {
      let keypressed = keycode(e.which);

      if (['down','up','esc','enter','tab'].indexOf(keypressed) != -1) {
        this.cancel = true;
      }

      this.move(e);
    }
    keyup = (e) => {
      switch (keycode(e.which)) {
        case 'down':
        case 'up':
        case 'shift':
        case 'ctrl':
        case 'command':
        case 'alt':
          break;
        case 'esc':
          this.close();
          break;
        case 'tab':
        case 'enter':
          if (!this.opened) {
            return;
          }
          this.select();
          break;
        default:
          this.search();
      }

      e.stopPropagation();
      e.preventDefault();
    }
    keypress = (e) => {
      if (this.cancel) {
        return;
      }

      this.move(e);
    }
    focus = () => {
      const valLen = this.el.value.length;

      this.el.setSelectionRange(valLen, valLen); // places cursor at the end so the user can continue typing
      this.search();
    }
    move = (e) => {
      if (!this.opened) {
        return;
      }

      switch (keycode(e.which)) {
        case 'down':
          e.preventDefault();
          this.highlight('down');
          break;
        case 'up':
          e.preventDefault();
          this.highlight('up');
          break;
        case 'esc':
        case 'tab':
        case 'enter':
          e.preventDefault();
          break;
      }
      e.stopPropagation();
    }
    close = () => {
      Utils.removeClass(this.dropdown, CLASSES.open);
      this.clear();
      this.opened = false;
    }
    open = () => {
      this.opened = true;
      Utils.addClass(this.dropdown, CLASSES.open);
    }
    clear = () => {
      this.menu.innerHTML = '';
    }
    search() {
      let url, fetchOpts;

      // skip search if current query is the same as the cached query but only if the result panel is already open
      if (this.query === this.cachedQuery && this.opened) {
        return;
      }

      this.cachedQuery = this.query;

      url = Utils.updateQueryString('term', this.query, this.endpoint);

      fetchOpts = {
        method: 'GET',
        id: this.query
      };

      Fetch(url, (data, id) => {
        // skip response if id is not the same as the current query
        if (id === this.query) {
          this.searchHandler(data);
        }
      }, fetchOpts);
    }
    searchHandler = (obj) => {
      this.clear();

      // no need to continue when there is nothing to show
      if (Object.keys(obj).length == 0) {
        this.close();
        return;
      }

      let fragment = document.createDocumentFragment();

      for (let index in obj) {
        let item = obj[index];
        let menuItem = Utils.createEl('li', CLASSES.dropdownItem);

        if (!('score' in item)) { // the presence of a score property means it's an autocomplete suggestion. So its absense means it's a recent search
          Utils.addClass(menuItem, CLASSES.dropdownItemRecentSearch);
        }

        const htmlNode = document.getElementsByTagName('html')[0];
        let value = item[this.key];
        if (htmlNode.getAttribute('lang') == 'en' && this.key === 'url') {
            value = `/en${value}`;
        }

        menuItem.innerHTML = this.format(item[this.displayKey]);
        menuItem.setAttribute(ATTRIBUTES.value, value);
        fragment.appendChild(menuItem);
      }

      this.menu.appendChild(fragment);
      this.open();
    }
    format(label, query = this.query) {
      let r = new RegExp('(?:' + query + ')', 'i');

      return label.replace(r, '<strong class="highlight">$&</strong>');
    }
    highlight(direction) {
      if (!this.highlighted) {
        this.highlighted = this.menu.children[0];
      } else {
        Utils.removeClass(this.highlighted, CLASSES.dropdownItemSelected);

        if (direction == 'down') {
          this.highlighted = this.highlighted.nextSibling;

          if (this.highlighted == null) {
            this.highlighted = this.menu.children[0];
          }
        } else {
          this.highlighted = this.highlighted.previousSibling;

          if (this.highlighted == null) {
            this.highlighted = this.menu.children[this.menu.children.length - 1];
          }
        }
      }

      this.el.value = this.highlighted.textContent;
      Utils.addClass(this.highlighted, CLASSES.dropdownItemSelected);
    }
    select = (e = null) => {
      let selected = null;
      let value = null;
      if (e) {
        selected = e.target;
      } else {
        selected = this.menu.querySelector(SELECTORS.dropdownItemSelected);
      }
      if (!selected) {
        selected = this.menu.children[0];
      }
      this.el.value = selected.textContent;
      value = selected.getAttribute(ATTRIBUTES.value);
      Utils.addClass(selected, CLASSES.dropdownItemSelected);
      this.close();
      this.callback(value);
    }
    dispose() {
      this.el.removeEventListener('keydown', this.keydown);
      this.el.removeEventListener('keyup', this.keyup);
      this.el.removeEventListener('keypress', this.keypress);
      this.menu.removeEventListener('click', this.select);

      delete this.menu;
    }
    static getAttribute() {
      return ATTRIBUTES.autocomplete;
    }
    static getSelector() {
      return SELECTORS.autocomplete;
    }
    get query() {
      return this.el.value;
    }
  }

  return AutoComplete;
})()

module.exports = AutoComplete;
