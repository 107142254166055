import Utils from 'utils';
import scrollToTop from 'utils/scroll-to-top';

const SmoothLinkScroll = (() => {
  const ATTRIBUTES = {
    'scroll': 'data-smooth-link-scroll',
  }

  const SELECTORS = {
    'scroll': `[${ATTRIBUTES.scroll}]`,
  }

  class SmoothLinkScroll {
    constructor(options) {
      this.el = options.el;

      this.initListeners();
    }

    initListeners() {
      this.el.addEventListener('click', (evt) => {
        evt.preventDefault();

        this.doScroll();
      });
    }

    doScroll = () => {
      let windowHeight = window.innerHeight;
      let target = this.getTarget();
      let elHeight = target.clientHeight;
      let elTop = Utils.getOffset(target).top;
      let scrollToY;

      if (windowHeight > elHeight) {
        scrollToY = elTop - (windowHeight / 2) + (elHeight / 2);
      } else {
        scrollToY = elTop;
      }

      scrollToTop(scrollToY);
    }

    getTarget() {
      let hrefParts = this.el.href.split('#');
      let target;

      if (hrefParts.length > 0) {
        target = document.getElementById(hrefParts[1]);
      }

      if (target) {
        return target;
      }

      throw new Error('No target');
    }

    static getSelector() {
      return SELECTORS.scroll;
    }
  }

  return SmoothLinkScroll;

})();

module.exports = SmoothLinkScroll;
