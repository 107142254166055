import FormModal from 'utils/form-modal';
import Utils from 'utils';

const ListingUnpublishModal = (() => {
    const NAME = 'listing-unpublish';
    const SUCCESS_NAME = `${NAME}_success`;

    const SELECTORS = {
        'id': NAME,
        'trigger': `[data-modal="${NAME}"]`,
    };

    class ListingUnpublishModal extends FormModal {
        init (options = {}) {
            super.init(options);

            this.label = null;

            if (null !== options.label) {
                this.label = Utils.urldecode(options.label);
            }
        }

        formHandler (data) {
            // handle when success modal is returned
            if (data.name === SUCCESS_NAME) {
                if ('reload' in data && data.reload === true) {
                    this.modal.closeHandler = () => {
                        window.location.href = window.location;
                    }
                }
            }
        }

        static getSelector () {
            return SELECTORS.trigger;
        }

        static getId () {
            return SELECTORS.id;
        }
    }

    return ListingUnpublishModal;
})();

export default ListingUnpublishModal;
