import Fetch from 'utils/fetch';

const ConsentModal = (() => {
  class ConsentModal {
    constructor(options = {}) {
        this.modal = options.modal;
        this.modal.closeHandler = () => {
            Fetch(window.HW.paths.consent_modal_submit);
        }
    }
    dispose(){}
  }

  return ConsentModal;
})();

export default ConsentModal;
