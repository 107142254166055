export function findOne (selector, root=document) {
    return root.querySelector(selector);
}

export function findAll (selector, root=document) {
    return Array.from(root.querySelectorAll(selector));
}

export function hasParent (target, parent) {
    let currentParent = target.parentNode;

    while (currentParent) {
        if (currentParent == parent) {
            return true
        }

        currentParent = currentParent.parentNode;
    }

    return false;
}

export function findAncestor (parentSelector, element) {
    let currentParent = element.parentNode;
    let matchesMethod = [
        'matches', 'matchesSelector',
        'mozMatchesSelector', 'msMatchesSelector',
        'oMatchesSelector', 'webkitMatchesSelector'
    ].find(methodName => methodName in element);

    while (currentParent && matchesMethod in currentParent) {
        if (currentParent[matchesMethod](parentSelector)) {
            return currentParent;
        }

        currentParent = currentParent.parentNode;
    }

    return null;
}

export function offsetRelativeToAncestor (element, ancestor) {
    let offsetElement = element;
    let offsetLeft = 0;
    let offsetTop = 0;

    while (offsetElement) {
        offsetLeft += offsetElement.offsetLeft;
        offsetTop += offsetElement.offsetTop;

        offsetElement = offsetElement == ancestor ? null : offsetElement.offsetParent;
    }

    return {
        left: offsetLeft,
        top: offsetTop,
    };
}

export function getJSONAttribute (element, attributeName) {
    const attributeData = element.getAttribute(attributeName);

    if (attributeData) {
        return JSON.parse(attributeData);
    }

    return {};
}
