import GoogleMapsLoader from 'google-maps';

export const loadGoogleMaps = function(callback) {
  GoogleMapsLoader.KEY = HW.config.map_key;
  GoogleMapsLoader.LANGUAGE = 'nl';
  GoogleMapsLoader.REGION = 'NL';
  GoogleMapsLoader.VERSION = 'quarterly';
  GoogleMapsLoader.load(callback);
}

module.exports = loadGoogleMaps;
