import FormModal from 'utils/form-modal'
import Utils from 'utils'

const ReportModal = (() => {
  class ReportModal extends FormModal {
    constructor(options = {}) {
      super(options)
    }
  }

  return ReportModal
})()

export default ReportModal
