import Fetch from 'utils/fetch';
import serialize from 'form-serialize';
import Utils from 'utils';

class SearchClient {
  constructor(callback) {
    this.callback = callback || function() {};
    this.fetches  = [];
    this.ready    = false;
    this.url      = null;
    this._form    = null;
    this._path    = null;
  }
  fetch(url = null, e = null, args = null) {
    let queryString, locationRegex, match;

    // url is specified when navigating by filter href
    if (url) {
      // strip the domain from endpoint
      this.url = url.replace(/https?:\/\/[^\/]+/i, "");
      // add leading slash before endpoint if not provided
      if (this.url.indexOf('/', 0) > 0) {
        this.url = `/${this.url}`;
      }

      // args are specified when browser back/forward button has been clicked
      if (args) {
        this.url = `${this.url}?${args}`;
      }
    // else a filter form element has been clicked
    } else {
      queryString = serialize(this.form);
      // use base listing search url to reset canonical urls
      // for example: deselecting 'huis' in the filter on '/huis-verhuren/' wouldn't work
      this.url = `${this.path}?${queryString}`
    }

    // strip out parameters not needed for request
    this.url = Utils.updateQueryString('location', null, this.url);

    let fetchOpts = {
      method: 'GET',
      id: this.url
    }

    Fetch(this.url, (data, id) => {
      // skip response if id is not the same as the current url
      if (id === this.url) {
        this.callback(data, e, this.getUrl(data));
      }
    }, fetchOpts);
  }
  getUrl(data) {
    if (data.url) {
      return data.url;
    }

    return this.url;
  }
  set form(form) {
    this._form = form;
  }
  get form() {
    return this._form;
  }
  set path(path) {
    this._path = path;
  }
  get path() {
    return this._path;
  }

}

module.exports = SearchClient;
