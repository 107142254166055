import Utils from 'utils'

const Dropdown = (() => {
  const ATTRIBUTES = {
    'toggle': 'data-toggle',
  }
  const CLASSES = {
    'dropdown': 'dropdown',
    'open': 'open'
  }
  const SELECTORS = {
    'dropdown': `[${ATTRIBUTES.toggle}="${CLASSES.dropdown}"]`,
  }

  class Dropdown {
    constructor(options = {}) {
      this.el = options.el
      this.container = this.el.parentNode
      if (Utils.hasClass(this.container, CLASSES.dropdown)) {
        // already initialized
        return
      }
      this.init()
    }
    init() {
      this.el.addEventListener('click', this.toggle)
      this.container.addEventListener('click', this.stop)
      document.addEventListener('click', this.close)
      Utils.addClass(this.container, CLASSES.dropdown)
    }
    toggle = (e) => {
      e.preventDefault()
      e.stopPropagation()
      Utils.toggle(this.container, CLASSES.open)
    }
    stop = (e) => {
      e.stopPropagation()
    }
    close = (e) => {
      Utils.removeClass(this.container, CLASSES.open)
    }
    dispose() {
      this.el.removeEventListener('click', this.toggle)
      this.container.addEventListener('click', this.stop)
      document.removeEventListener('click', this.close)
    }
    static getSelector() {
      return SELECTORS.dropdown
    }
  }

  return Dropdown
})()

module.exports = Dropdown
