import Utils from 'utils'

const Notification = (() => {
  const ATTRIBUTES = {
    'notification': 'data-notification',
    'close': 'data-notification-close'
  }
  const CLASSES = {
    'hide': 'notification--hide',
  }
  const SELECTORS = {
    'notification': `[${ATTRIBUTES.notification}]`,
    'close': `[${ATTRIBUTES.close}]`,
  }

  class Notification {
    constructor(options = {}) {
      this.unbinders = []
      this.init()
    }
    init() {
      let notifications = document.querySelectorAll(SELECTORS.notification)
      Utils.forEach(notifications, (el) => {
        let closeBtn = el.querySelector(SELECTORS.close)
        if (!closeBtn) {
          return
        }

        let callback = function() {
          Utils.addClass(el, CLASSES.hide)
          setTimeout(function() {
            Utils.hide(el)
          }, 500)
        }
        let unbinder = function() {
          closeBtn.removeEventListener('click', callback)
        }
        this.unbinders.push(unbinder)
        closeBtn.addEventListener('click', callback)
      })
    }
    dispose() {
      this.unbinders.map(function(unbinder) {
        unbinder()
      })
      this.unbinders = []
    }
    static getSelector() {
      return SELECTORS.notification
    }
  }

  return Notification
})()

module.exports = Notification
