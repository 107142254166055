// polyfills for stimulus
import 'core-js/features/object/assign';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/promise';
import 'core-js/features/array/find';
import 'core-js/features/array/from';

// libs
import { Application } from 'stimulus';

// components
import contactForm from 'components/contact-form/contact-form';
import dashboardNavigation from 'components/dashboard-navigation/dashboard-navigation';
import listingContactInfo from 'components/listing-contact-info/listing-contact-info';
import ListingPhotoImage from 'components/listing-photo-image/listing-photo-image';
import ListingPhotoUploader from 'components/listing-photo-uploader/listing-photo-uploader';
import ListingPhotoSorter from 'components/listing-photo-sorter/listing-photo-sorter';
import LoadingIndicator from 'components/loading-indicator/loading-indicator';
import SearchMap from 'components/search-map/search-map';
import Accordion from 'components/../../../src/Website/Component/Accordion/Accordion';
/* component generator replace here */

// this is the main application entrypoint
// polyfill to make class inheritance work in IE < 11
// see: https://phabricator.babeljs.io/T3041#67582
(function() {
  if (!(Object.setPrototypeOf || {}.__proto__)) {
    let nativeGetPrototypeOf = Object.getPrototypeOf;

    Object.getPrototypeOf = function(object) {
      if (object.__proto__) {
        return object.__proto__;
      } else {
        return nativeGetPrototypeOf.call(Object, object);
      }
    }
  }
})();

const controllers = [
    contactForm,
    dashboardNavigation,
    listingContactInfo,
    SearchMap,
    LoadingIndicator,
    ListingPhotoImage,
    ListingPhotoUploader,
    ListingPhotoSorter,
    Accordion
    /* js component here */
];

const application = Application.start();

controllers.forEach((controllerModule) => {
    application.register(controllerModule.name, controllerModule.controller);
});

// this is the main application entrypoint
import App from 'assets/js/app';
new App();
