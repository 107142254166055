import {ComponentController} from 'helpers/stimulus/component-controller';
import {bind} from 'hyperhtml/umd';
import {renderClasses} from "helpers/bem/bem";
import Fetch from "utils/fetch";

const NAME = 'listing-photo-image';

const MESSAGE_HANDLERS = [
    'listingPhotoUploaderEnded.all',
    'listingPhotoUploaderFailed.all',
];

const MESSAGES = {
    'resetInputField': 'resetUploadInputField',
    'removed': 'listingPhotoImageRemoved',
};

const CLASSES = {
    'isLoading': renderClasses(NAME, null, ['loading']),
    'error': renderClasses(NAME, 'error'),
    'hasError': renderClasses(NAME, 'error', ['visible']),
};

const SELECTOR = {
    'error': `.${CLASSES.error}`,
};

class ListingPhotoImage extends ComponentController {
    onListingPhotoUploaderEnded (evt) {
        if (evt.data.data.scope === this.data.get('scope')) {
            const parent = this.element.parentElement;
            parent.setAttribute('data-id', evt.data.data.photo_id);

            const itemNumber = parent.removeChild(parent.lastElementChild);

            this.element.parentElement.classList.remove('disable-drag');

            bind(parent)`
                ${[evt.data.data.html]}
                ${[itemNumber]}
            `;
        }
    }

    onListingPhotoUploaderFailed (evt) {
        if (evt.data.data.scope === this.data.get('scope')) {
            this.element.classList.remove(CLASSES.isLoading);
            this.element.parentElement.classList.add('disable-drag');
            this.element.querySelector(SELECTOR.error).classList.add(CLASSES.hasError);
        }
    }

    onDeleteImage (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.element.classList.add(CLASSES.isLoading);

        Fetch(evt.target.getAttribute('href'), () => {
            const li = this.element.parentElement;
            li.parentElement.removeChild(li);

            this.messageBus.postMessage({
                'message': MESSAGES.removed,
            });
        });
    }

    onDeleteFaultyImage (evt) {
        evt.preventDefault();
        evt.stopPropagation();

        this.messageBus.postMessage({
            'message': MESSAGES.resetInputField,
        });

        const li = this.element.parentElement;
        li.parentElement.removeChild(li);
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': ListingPhotoImage
};
