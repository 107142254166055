import Utils from 'utils';
import scrollToTop from 'utils/scroll-to-top';

const Collapse = (() => {
  const ATTRIBUTES = {
    'collapse': 'data-collapse',
    'toggle': 'data-collapse-toggle',
    'toggleType': 'data-collapse-toggle-type',
    'toggleContainer': 'data-collapse-toggle-container',
    'type': 'data-collapse-type',
    'modifier': 'data-collapse-modifier',
    'media': 'data-collapse-media',
    'limit': 'data-collapse-limit',
  }

  const BEM = {
    'collapseBlock': 'collapse',
    'toggleElement': '__toggle',
    'contentElement': '__content',
    'openModifier': '--open',
    'textModifier': '--text',
    'iconModifier': '--icon',
    'labelModifier': '--label',
    'arrowModifier': '--arrow',
  }

  const CLASSES = {
    'collapse': BEM.collapseBlock,
    'collapseToggle': `${BEM.collapseBlock}${BEM.toggleElement}`,
    'collapseContent': `${BEM.collapseBlock}${BEM.contentElement}`,
    'collapseText': `${BEM.collapseBlock}${BEM.textModifier}`,
    'collapseOpen': `${BEM.collapseBlock}${BEM.openModifier}`,
  }
  CLASSES.collapseToggleIcon = `${CLASSES.collapseToggle}${BEM.iconModifier}`;
  CLASSES.collapseToggleArrow = `${CLASSES.collapseToggle}${BEM.arrowModifier}`;
  CLASSES.collapseToggleLabel = `${CLASSES.collapseToggle}${BEM.labelModifier}`;

  const SELECTORS = {
    'collapse': `[${ATTRIBUTES.collapse}]`,
    'toggle': `[${ATTRIBUTES.toggle}]`,
    'toggleType': `[${ATTRIBUTES.toggleType}]`,
    'toggleContainer': `[${ATTRIBUTES.toggleContainer}]`,
  }

  const LABELS = {
    'showLess': HW.i18n.collapse.show_less,
    'showMore': HW.i18n.collapse.show_more,
  }
  const LIMIT = 5;

  const TOGGLE_TYPE = 'icon';

  class Collapse {
    constructor(options = {}) {
      this.el            = options.el;
      this.callback      = options.callback || Utils.noop;
      this.toggleEl      = null;
      this.offsetTop     = null;

      this.unbinders     = [];

      this.init();
    }
    init() {
      this.offsetTop = Utils.getOffsetTop(this.el);

      // only collapse on mobile, when configured via media attribute
      // when not configured all media will collapse the el
      if (this.el.getAttribute(ATTRIBUTES.media) == 'mobile' && !Utils.isMobile(false)) {
        return;
      }

      if (this.el.getAttribute(ATTRIBUTES.media) == 'not-mobile' && Utils.isMobile(false)) {
        return;
      }

      if (this.children.length <= this.limit) {
        return;
      }

      Utils.addClass(this.el, CLASSES.collapse);

      if (this.el.hasAttribute(ATTRIBUTES.modifier)) {
        Utils.addClass(this.el, `${CLASSES.collapse}--${this.el.getAttribute(ATTRIBUTES.modifier)}`);
      }

      this.initToggle();

      Utils.forEach(this.children, function(el) {
        Utils.addClass(el, CLASSES.collapseContent);
      });

      if (this.type == 'text') {
        Utils.addClass(this.el, CLASSES.collapseText);
        this.hide(false);
      } else {
        if (this.shouldToggle()) {
          this.hide();
        } else {
          this.show();
        }
      }
    }
    initToggle() {
      this.toggleEl = this.createToggleEl();

      Utils.addClass(this.toggleEl, CLASSES.collapseToggle);
      this.toggleEl.addEventListener('click', this.toggle);

      this.addUnbinder(() => {
        this.toggleEl.removeEventListener('click', this.toggle);
      });
    }
    createToggleEl() {
      if (this.toggleType == 'label') {
        let toggleContainerSelector = this.el.getAttribute(ATTRIBUTES.toggleContainer);
        let toggleContainer = this.el.querySelector(`.${toggleContainerSelector}`) || this.el;
        let el = Utils.createEl('button', CLASSES.collapseTogle);
        el.type = 'button';
        Utils.addClass(el, CLASSES.collapseToggleLabel);
        toggleContainer.appendChild(el);

        this.addUnbinder(() => {
          toggleContainer.removeChild(el);
        });

        return el;
      }

      if (this.toggleType == 'icon') {
        let toggleSelector = this.el.getAttribute(ATTRIBUTES.toggle);
        let el = this.el.querySelector(`.${toggleSelector}`);
        Utils.addClass(el, CLASSES.collapseToggleIcon);

        return el;
      }

      if (this.toggleType == 'arrow') {
        let toggleSelector = this.el.getAttribute(ATTRIBUTES.toggle);
        let el = this.el.querySelector(`.${toggleSelector}`);
        Utils.addClass(el, CLASSES.collapseToggleArrow);

        return el;
      }
    }
    toggle = () => {
      if (Utils.hasClass(this.el, CLASSES.collapseOpen)) {
        this.hide();
      } else {
        this.show();
      }
      this.callback();
    }
    toggleLabel(label) {
      if (this.toggleType != 'label') {
        return;
      }

      this.toggleEl.textContent = label;
    }
    show() {
      Utils.addClass(this.el, CLASSES.collapseOpen);

      this.toggleLabel(LABELS.showLess);

      Utils.forEach(this.children, (el, i) => {
        if (this.type == 'text') {
          return el.style.height = 'auto';
        }
        if (i > this.limit - 1) {
          return el.style.display = '';
        }
      });
    }
    hide(shouldScroll = true) {
      Utils.removeClass(this.el, CLASSES.collapseOpen);

      this.toggleLabel(LABELS.showMore);

      if (this.type == 'text' && shouldScroll) {
        scrollToTop(this.offsetTop);
      }

      Utils.forEach(this.children, (el, i) => {
        if (this.type == 'text') {
          return el.style.height = '';
        }
        if (i > this.limit - 1) {
          return el.style.display = 'none';
        }
      });
    }
    dispose() {
      if (!this.toggleEl) {
        return;
      }

      this.unbinders.map((unbinder) => {
        unbinder();
        this.unbinders = [];
      });

      this.toggleEl.removeEventListener('click', this.toggle);
    }
    shouldToggle() {
      let toggle = true;
      Utils.forEach(this.children, function(el) {
        let input = el.querySelector('input');
        if (input && input.checked) {
          toggle = false;
        }
      });

      return toggle;
    }
    addUnbinder(unbinder) {
      this.unbinders.push(unbinder);
    }
    get limit() {
      if (Utils.isMobile(false) || this.type == 'text') {
        return 0;
      }

      return this.el.getAttribute(ATTRIBUTES.limit) || LIMIT;
    }
    get type() {
      return this.el.getAttribute(ATTRIBUTES.type);
    }
    get children() {
      let childSelector = this.el.getAttribute(ATTRIBUTES.collapse);
      return this.el.querySelectorAll(`.${childSelector}`);
    }
    get toggleType() {
      return this.el.getAttribute(ATTRIBUTES.toggleType) || TOGGLE_TYPE;
    }
    static getSelector() {
      return SELECTORS.collapse;
    }
  }

  return Collapse;
})();

module.exports = Collapse;
