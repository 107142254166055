import Utils from "utils";

const Accordion = (() => {
  const ATTRIBUTES = {
    'accordion': 'data-accordion',
  }

  const BEM = {
    'accordionBlock': '%s',
    'toggleElement': '__toggle',
    'hiddenModifier': '--hidden',
  }

  const CLASSES = {
    'accordion': BEM.accordionBlock,
    'accordionToggle': `${BEM.accordionBlock}${BEM.toggleElement}`,
    'accordionHidden': `${BEM.accordionBlock}${BEM.hiddenModifier}`,
  }

  const SELECTORS = {
    'accordion': `[${ATTRIBUTES.accordion}]`,
    'accordionToggle': `.${CLASSES.accordionToggle}`,
  }

  class Accordion {
    constructor(options={}) {
      this.el      = options.el;
      this.toggles = null;
      this.blockName = null;

      this.init();
    }
    init() {
      this.blockName = this.el.getAttribute(ATTRIBUTES.accordion) || 'accordion';

      this.toggles = this.el.querySelectorAll(this.getClassName(SELECTORS.accordionToggle));
      Utils.forEach(this.toggles, this.addListeners);
    }
    addListeners = (el) => {
      el.addEventListener('click', this.clickHandler);
    }
    clickHandler = (e) => {
      let toggle = e.currentTarget;
      let container = toggle.parentElement;
      Utils.toggle(container, this.getClassName(CLASSES.accordionHidden));
      e.preventDefault();
    }
    getClassName(name) {
      return name.replace('%s', this.blockName);
    }
    static getSelector() {
      return SELECTORS.accordion;
    }
  }

  return Accordion;
})();

module.exports = Accordion;
