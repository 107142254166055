import Utils from 'utils';

const ClearInput = (() => {
  const CLASSES = {
    'button': 'control-group__clear',
    'hidden_button': 'control-group__clear--hidden',
  };

  const ATTR = {
    'clear_input': 'data-clear-input',
  };

  const SELECTORS = {
    'clear_input': `[${ATTR.clear_input}]`,
  };

  class ClearInput {
    constructor(options) {
      this.el = options.el;

      this.init();
    }

    init = () => {
      this.buildClearButton();
      this.addEventListeners();
      this.toggleVisibilityOnValue();
    }

    buildClearButton = () => {
      const button = document.createElement('button');

      button.className = CLASSES.button;
      button.type = 'button';
      button.innerHTML = HW.i18n.clear_input.button_label;

      this.clearBtn = button;

      this.el.parentNode.appendChild(button);
    }

    addEventListeners = () => {
      this.clearBtn.addEventListener('click', this.clearBtnClick);
      this.el.addEventListener('change', this.toggleVisibilityOnValue);
      this.el.addEventListener('keyup', this.toggleVisibilityOnValue);
    }

    toggleVisibilityOnValue = () => {
      let method = 'removeClass';

      if (this.el.value.length == 0) {
        method = 'addClass';
      }

      Utils[method](this.clearBtn, CLASSES.hidden_button);
    }

    clearBtnClick = () => {
      this.el.value = '';
      this.el.focus();
      this.toggleVisibilityOnValue();
    }

    static getSelector() {
      return SELECTORS.clear_input;
    }
  }

  return ClearInput;
})();

module.exports = ClearInput;
