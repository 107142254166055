import Utils from 'utils';

import FormChoice from 'form/choice';
import FormInput from 'form/input';
import FormSelect from 'form/select';
import FormSelectOptgroups from 'form/select-optgroups';
import LocationSuggester from 'form/location-suggester';
import scrollToTop from 'utils/scroll-to-top';
import Fetch from 'utils/fetch';
import serialize from 'form-serialize';
import Modal from 'modal';

const Form = (() => {
  const CLASSES = {
    'error': 'error',
    'success': 'notification--success',
    'btnLoading': 'btn--loading',
  }
  const ATTRIBUTES = {
    'form': 'data-form',
    'async': 'data-form-async',
    'container': 'data-form-container',
    'asyncTrigger': 'data-form-async-trigger',
    'confirm': 'data-form-confirm',
  }
  const SELECTORS = {
    'form': `[${ATTRIBUTES.form}]`,
    'async': `[${ATTRIBUTES.async}]`,
    'error': `.${CLASSES.error}`,
    'success': `.${CLASSES.success}`,
  }

  class Form {
    constructor(options = {}) {
      this.form        = options.form;
      this.container   = null;
      this.submit      = null;
      this.disposables = [];
      this._formData   = null;
      this.init();
    }
    init() {
      this.submit = this.form.querySelector('[type="submit"]');

      Utils.forEach(this.form.querySelectorAll(FormInput.getSelector()), (el) => {
        let disposable = new FormInput({el: el});
        this.disposables.push(disposable);
      });
      Utils.forEach(this.form.querySelectorAll(FormSelect.getSelector()), (el) => {
        let disposable = new FormSelect({el: el, form: this});
        this.disposables.push(disposable);
      });
      Utils.forEach(this.form.querySelectorAll(FormSelectOptgroups.getSelector()), (el) => {
        let disposable = new FormSelectOptgroups({el: el});
        this.disposables.push(disposable);
      });
      Utils.forEach(this.form.querySelectorAll(FormChoice.getSelector()), (el) => {
        let disposable = new FormChoice({el: el, form: this});
        this.disposables.push(disposable);
      });
      Utils.forEach(this.form.querySelectorAll(LocationSuggester.getSelector()), (el) => {
        let disposable = new LocationSuggester({el: el});
        this.disposables.push(disposable);
      });

      this.addListeners();
    }
    addListeners() {
      // when fetch request returns unauthorized response, enable the submit button
      HW.app.events.on('unauthorized', this.unauthorizedHandler);
      this.form.addEventListener('submit', this.submitHandler);
      let containerSelector = `[${ATTRIBUTES.container}="${this.form.id}"]`;
      this.container = document.querySelector(containerSelector) || this.form;
    }
    unauthorizedHandler = () => {
      this.enableSubmit();
    }
    submitHandler = (e) => {
      e && e.preventDefault();
      this.disableSubmit();

      if (this.confirm) {
        if (window.confirm(this.form.getAttribute(ATTRIBUTES.confirm))) {
          this.submitForm();
        } else {
          this.enableSubmit();
        }
      } else {
        this.submitForm();
      }
    }
    submitForm() {
      if (this.async) {
        let body = serialize(this.form);
        let fetchArgs = {
          method: 'POST',
          body: body,
          transform: this.transformBody
        }
        Fetch(this.endpoint, this.responseHandler, fetchArgs);
      } else {
        this.form.submit();
      }
    }
    disableSubmit() {
      this.submit.disabled = true;
      Utils.addClass(this.submit, CLASSES.btnLoading);
    }
    enableSubmit() {
      this.submit.disabled = false;
      Utils.removeClass(this.submit, CLASSES.btnLoading);
    }
    transformBody(body) {
      return body;
    }
    responseHandler = (data) => {
      if (data) {
        if (this.form && !data.name) {
          data['name'] = this.form.id;
        }

        if (data.html) {
          this.container.innerHTML = data.html;
        }

        this.dispose();
        // if the returned content contains an async form, re initialize form class
        this.form = this.container.querySelector(SELECTORS.form);
        if (this.form) {
          this.init();
        }
        // TODO: should be triggered when init is ready
        HW.app.events.trigger('form:success', data);

        // when form has errors, scroll to first form element containing error
        // we only do this if there is not a modal open
        if (!Modal.isOpen()) {
          if (!data.ok) {
            const firstError = this.form.querySelector(SELECTORS.error);
            if (firstError) {
              const firstErrorTop = Utils.findPosY(firstError);
              scrollToTop(firstErrorTop);
            }
          } else {
            const successNotification = this.container.querySelector(SELECTORS.success);
            if (successNotification) {
              const successNotificationTop = Utils.findPosY(successNotification);
              scrollToTop(successNotificationTop);
            }
          }
        }
      } else {
        // not implemented yet
        return;
      }
    }
    dispose() {
      if (this.form) {
        this.form.removeEventListener('submit', this.submitHandler);
        this.disposables.map((disposable) => {
          disposable.dispose();
        })
        this.disposables = [];
        this.form = null;
      }
    }
    static getSelector() {
      return SELECTORS.form;
    }
    static getBtnLoadingClass() {
      return CLASSES.btnLoading;
    }
    get asyncTrigger() {
      return ATTRIBUTES.asyncTrigger;
    }
    get async() {
      return this.form.hasAttribute(ATTRIBUTES.async) && !window.HW.config.disable_async_forms;
    }
    get confirm() {
      return this.form.hasAttribute(ATTRIBUTES.confirm);
    }
    get formData() {
      return this._formData;
    }
    set formData(data) {
      this._formData = data;
    }
    get endpoint() {
      // fallback for forms with empty actions, resulting in empty string and eventually breaking IE
      if (this.form.action == '') {
        return document.location.href;
      }

      return this.form.action;
    }
  }

  return Form;
})();

export default Form;
