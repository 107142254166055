import AutoComplete from 'autocomplete';
import FormInput from 'form/input';
import Utils from 'utils';

const LocationSuggester = (() => {
  const ATTRIBUTES = {
    'location': 'data-location',
    'endpoint': 'data-location-endpoint',
    'target': 'data-location-target',
    'params': 'data-location-params',

  }
  const SELECTORS = {
    'location': `[${ATTRIBUTES.location}]`,
    'endpoint': `[${ATTRIBUTES.endpoint}]`,
    'target': `[${ATTRIBUTES.target}]`,
    'params': `[${ATTRIBUTES.params}]`,
  }

  class LocationSuggester {
    constructor(options = {}) {
      this.el       = options.el;
      this.type     = null;
      this.endpoint = null;
      this.target   = null;
      this.params   = null;
      this.form     = null;
      this.ac       = null;

      this.init();
    }
    init() {
      this.type     = this.el.getAttribute(ATTRIBUTES.location);
      this.endpoint = this.el.getAttribute(ATTRIBUTES.endpoint) || HW.paths.api_search_locations;
      this.target   = this.el.getAttribute(ATTRIBUTES.target);
      this.params   = this.el.getAttribute(ATTRIBUTES.params) || '{}';

      this.initAutoComplete();
    }
    initAutoComplete() {
      this.ac = new AutoComplete({
        el: this.el,
        endpoint: HW.paths.api_search_locations,
        params: JSON.parse(this.params),
        key: this.type
      }, this.selectHandler);
    }
    selectHandler = (value) => {
      switch(this.type) {
        case 'url':
          // TODO: add custom endpoint for listing requests location urls
          let href = this.endpoint == 'listing-requests' ? value.replace('in', 'te-huur-gezocht') : value;
          if (this.target == 'action') {
            this.el.form.action = href;
            Utils.addClass(this.el.parentNode, FormInput.getActiveClass());
          } else {
            // target is skipped, just set location
            location.href = href;
          }
          break;
        default:
          let target = document.getElementById(this.target);
          target.value = value;
          break;
      }
      HW.app.events.trigger('autocomplete:changed');
    }
    dispose() {
      this.ac.dispose();
    }
    static getAttribute() {
      return ATTRIBUTES.location;
    }
    static getSelector() {
      return SELECTORS.location;
    }
  }

  return LocationSuggester;
})();

module.exports = LocationSuggester;
