import FormModal from 'utils/form-modal'
import Utils from 'utils'

const AuthorizeModal = (() => {
  class AuthorizeModal extends FormModal {
    constructor(options = {}) {
      super(options)
    }
  }

  return AuthorizeModal
})()

export default AuthorizeModal
