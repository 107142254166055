import Utils from 'utils';
import Fetch from 'utils/fetch';
import * as modals from 'modals';
import Spinner from '../../components/spinner/spinner';

const Modal = (() => {
  const CLASSES = {
    'modalOpenedBody':    'modal-open',
    'modalOpenedModal':   'modal--opened',
    'modalPrefix':        'modal--',
    'disabled':           'disabled',
  }
  const ATTRIBUTES = {
    'trigger':      'data-modal',
    'source':       'data-modal-source',
    'close':        'data-modal-close',
  }
  const SELECTORS = {
    'modal':        'modal',
    'trigger':      `[${ATTRIBUTES.trigger}]`,
    'container':    'modal-container',
    'body':         'modal-body',
    'loader':       'modal-loader',
    'close':        `[${ATTRIBUTES.close}]`,
  }

  class Modal {
    constructor(options = {}) {
      this.modal         = document.getElementById(SELECTORS.modal);
      this.containerEl   = document.getElementById(SELECTORS.container);
      this.bodyEl        = document.getElementById(SELECTORS.body);
      this.modalLoader   = document.getElementById(SELECTORS.loader);

      // initial modal trigger on page load
      this.triggers         = document.querySelectorAll(SELECTORS.trigger);
      // reference modal content modal triggers (navigating in modals)
      this.contentTriggers  = [];
      // reference current loaded loaders
      this.loaded           = [];
      // reference current loaded modal arguments
      this.modalArgs        = {};

      // cache vars
      this.modalOpen     = false;
      this.lastFocus     = null;
      this.modalName     = null;
      this.disabled      = false;
      this.interstitial  = false;
    }
    init() {
      // close modal by keydown, but only if modal is open
      document.addEventListener('keydown', this._close);

      // restrict tab focus on elements only inside modal window
      document.addEventListener('focusin', this.restrictFocus);

      // close modal window by clicking on the overlay
      this.modal.addEventListener('click', this._closeClick);

      // close modal on back button click (or, navigating back in history), only when history API is available
      if (Utils.hasHistory()) {
        window.addEventListener('hashchange', this.handleBackButton);
      }

      // initialize modal listeners, like buttons
      this.initListeners();
    }
    initListeners() {
      Utils.forEach(this.triggers, this.addListener);
    }
    addListener = (el) => {
      // only add loader once
      if (this.loaded.indexOf(el) == -1) {
        el.addEventListener('click', this.clickHandler);
        this.loaded.push(el);
      }
    }
    removeListener = (removeEl) => {
      this.loaded = this.loaded.filter(el => el !== removeEl);
      removeEl.removeEventListener('click', this.clickHandler);
    }
    // adds listeners to the loaded content of the modal
    addContentListeners() {
      // first remove previous content listeners
      this.removeContentListeners();

      // check if the modal content needs to be enhanced

      // close buttons (icon & button)
      this.handleCloseButtons();

      // modal triggers
      this.contentTriggers = this.body.querySelectorAll(SELECTORS.trigger);
      // add modal trigger listeners
      Utils.forEach(this.contentTriggers, this.addListener);
    }
    removeContentListeners() {
      Utils.forEach(this.contentTriggers, this.removeListener);
      this.contentTriggers = [];
    }
    disableListeners() {
      if (this.disabled) {
        return;
      }
      this.disabled = true;
      Utils.forEach(this.loaded, function(el) {
        Utils.addClass(el, CLASSES.disabled);
      });
    }
    enableListeners() {
      if (!this.disabled) {
        return;
      }
      this.disabled = false;
      Utils.forEach(this.loaded, function(el) {
        Utils.removeClass(el, CLASSES.disabled);
      });
    }
    clickHandler = (event) => {
      let el = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();

      if (this.disabled) {
        return;
      }

      let modalName   = el.getAttribute(ATTRIBUTES.trigger);
      let modalSource = el.getAttribute(ATTRIBUTES.source);

      this.open(modalName, modalSource, el);
    }
    open = (name, source, trigger = null, interstitial = false) => {
      if (name == "") {
        throw new Error('name is required');
      }
      if (source == "") {
        throw new Error('source is required');
      }

      this.interstitial = interstitial;

      this.body = '';

      if (!this.modalOpen) {
        this.modal.setAttribute('aria-hidden', false);
        this.containerEl.setAttribute('tabindex', 0);
        this.modalOpen = true;

        this.modalName = name;

        // don't scroll underlaying content when modal is open
        Utils.addClass(document.body, CLASSES.modalOpenedBody);

        // this will visually open the modal
        Utils.addClass(this.modal, CLASSES.modalOpenedModal);
      } else {
        Utils.removeClass(this.modal, `${CLASSES.modalPrefix}${this.modalName}`);
        this.modalName = name;
      }

      // cache element currently having focus to restore it later
      this.lastFocus = document.activeElement;
      Utils.addClass(this.modal, `${CLASSES.modalPrefix}${this.modalName}`);

      this.showLoader();

      let endpoint = source == 'current' ? location.href : source;
      Fetch(endpoint, (data) => {
        let index = `modal_${name}`;
        let label = data['label'] || null;
        this.modalArgs = {
          'modal': this,
          'trigger': trigger,
          'label': label,
        }

        this.hideLoader(data, index, name);
      })
    }
    close = () => {
      if (true === this.interstitial) {
        return;
      }
      this.body = '';
      if (this.modalOpen) {
        modals.unload(this.modalArgs);

        Utils.removeClass(this.modal, `${CLASSES.modalPrefix}${this.modalName}`);
        Utils.removeClass(document.body, CLASSES.modalOpenedBody);
        Utils.removeClass(this.modal, CLASSES.modalOpenedModal);

        this.modalName = null;
        this.modalOpen = false;
        this.modalArgs = {};

        this.modal.setAttribute('aria-hidden', true);
        this.containerEl.setAttribute('tabindex', -1);

        this.lastFocus && this.lastFocus.focus();

        if (Utils.hasHistory()) {
          // remove hash from url
          history.pushState("", document.title, window.location.pathname + window.location.search);
        }

        this.closeHandler();
      }
    }
    showLoader() {
      this.spinner = new Spinner();
      this.containerEl.appendChild(this.spinner.element);
      this.spinner.fadeIn();
    }
    hideLoader(data, index, name) {
      this.spinner.remove( () => {
        this.body = data[index] ? data[index] : data['html'];
        this.addContentListeners();

        // load custom module
        modals.load(name, this.modalArgs);

        // change the hash (for closing on back button click)
        if (Utils.hasHistory()) {
          document.location.hash = 'modal';
        }
      });
    }
    handleCloseButtons() {
      let closeButtons = this.body.querySelectorAll(SELECTORS.close);
      Utils.forEach(closeButtons, (btn) => {
        let handler = () => {
          this.close();
          btn.removeEventListener('click', handler);
        }
        btn.addEventListener('click', handler);
      })
    }
    handleBackButton = () => {
      // ignore hash change when modal is opened
      if (document.location.hash == '#modal') {
        return;
      }
      this.close();
    }
    restrictFocus = (event) => {
      if (this.modalOpen && !this.modal.contains(event.target)) {
        event.preventDefault();
        this.containerEl.focus();
      }
    }
    closeHandler() {
      return;
    }
    // 'private' methods
    _closeClick = (e) => {
      if (e.target == this.modal) {
        this.close();
      }
    }
    _close = (e) => {
      if (e.keyCode && e.keyCode == 27) {
        this.close();
      }
    }
    get body() {
      return this.bodyEl;
    }
    set body(content) {
      this.bodyEl.innerHTML = content;
    }
    static isOpen() {
      return Utils.hasClass(document.body, CLASSES.modalOpenedBody);
    }
    static getSelector() {
      return SELECTORS.trigger;
    }
    static getBodySelector() {
      return SELECTORS.body;
    }
  }

  return Modal;
})();

export default Modal;
