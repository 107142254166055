import Utils from 'utils';
import SearchClient from 'utils/search-client';
import scrollToTop from 'utils/scroll-to-top';
import Collapse from 'collapse';
import Sticky from 'sticky';
import FormSelect from 'form/select';
import Form from 'form';
import Map from 'map';
import Modal from 'modal';
import PreviewSlideshow from 'preview-slideshow';

const Search = (() => {
    const ATTRIBUTES = {
        'toggle': 'data-search-toggle',
        'toggleList': 'list',
        'toggleMap': 'map',
        'favorite': 'data-form-favorite',
        'sort': 'data-attr-sort',
        'sortButton': 'toggle',
        'sortAsc': 'asc',
        'sortDesc': 'desc',
    }
    const CLASSES = {
        'directionAsc': 'sort-form__direction--asc',
        'directionDesc': 'sort-form__direction--desc',
        'listingLoading': 'listing--loading',
        'hideHint': 'new-results-hint-notification--hide',
        'sortDirection': 'sort-form__direction-toggle',
        'listignSearchFooter': 'listing-search__footer',
    }
    const SELECTORS = {
        'trigger': '[data-attr-async-trigger]',
        'direction': `[${ATTRIBUTES.sort}="${ATTRIBUTES.sortButton}"]`,
        'directionAsc': `[${ATTRIBUTES.sort}="${ATTRIBUTES.sortAsc}"]`,
        'directionDesc': `[${ATTRIBUTES.sort}="${ATTRIBUTES.sortDesc}"]`,
        'scroll': 'data-attr-scroll',
        'listing': '.listing',
        'firstListing': 'first-listing',
        'lastListing': 'last-listing',
        'searchListHeader': 'search-list-header',
        'actionBar': 'action-bar',
        'pagination': 'pagination',
        'listings': 'listings',
        'filtersAside': 'filters-aside',
        'filterForm': 'filter-form',
        'header': 'header',
        'newResultsHint': 'new-results-hint',
        'newResultsCount': 'new-results-count',
        'toggle': `[${ATTRIBUTES.toggle}]`,
        'map': 'search-map',
        'favorite': `[${ATTRIBUTES.favorite}]`,
        'mainContainer': 'main',
        'pageHeaderTitle': 'page-header-title',
        'pageHeaderIntro': 'page-header-description',
        'breadcrumbs': 'breadcrumbs',
        'listingSearchFooter': `.${CLASSES.listignSearchFooter}`,
    }

    class Search {
        constructor (options = {}) {
            this.isMapView = options.map || false;
            this.isRequestsView = options.requests_view || false;
            this.sortDirection = null;
            this.links = null;

            this.form = document.getElementById(SELECTORS.filterForm);
            this.listings = document.getElementById(SELECTORS.listings);
            this.mapContainer = document.getElementById(SELECTORS.map);
            this.pagination = document.getElementById(SELECTORS.pagination);
            this.searchListHeader = document.getElementById(SELECTORS.searchListHeader);
            this.actionBar = document.getElementById(SELECTORS.actionBar);
            this.filtersAside = document.getElementById(SELECTORS.filtersAside);
            this.header = document.getElementById(SELECTORS.header);
            this.newResultsHint = document.getElementById(SELECTORS.newResultsHint);
            this.newResultsCount = document.getElementById(SELECTORS.newResultsCount);
            this.mainContainer = document.getElementById(SELECTORS.mainContainer);
            this.pageHeaderTitle = document.getElementById(SELECTORS.pageHeaderTitle);
            this.pageHeaderIntro = document.getElementById(SELECTORS.pageHeaderIntro);
            this.breadcrumbs = document.getElementById(SELECTORS.breadcrumbs);

            this.toggles = [];
            this.modals = [];

            this.firstInit = true;

            this._results = null;
            this._url = null;

            // classes
            this.searchClient = null;
            this.collapse = null;
            this.sticky = null;
            this.unbinders = [];

            if (Utils.hasHistory()) {
                HW.app.route('*args', (route, args) => {
                    this.update(route, null, args);
                });

                // the main search app needs to be 'reloaded' because it's html has been swapped when filtering via filterModal
                HW.app.events.on('search.render', (args) => {
                    // when results we add a delay to rendering so we can show the loaders for a 700ms
                    let timeout = this.results ? 700 : 0;
                    setTimeout(() => {
                        this.render(this.results, null, this.url);
                    }, timeout);
                });
                HW.app.events.on('search.set_results', (args) => {
                    this.results = args.data;
                    this.url = args.url;
                })
                HW.app.events.on('search.show_loaders', (args) => {
                    this.showLoaders();
                });
            }

            this.init();

            if (this.isMapView) {
                this.initMap();
            }
        }

        init () {
            this.results = null;
            this.url = null;

            this.modals = this.mainContainer.querySelectorAll(Modal.getSelector());
            this.sortDirection = this.form.querySelector(SELECTORS.direction);
            this.toggles = document.querySelectorAll(SELECTORS.toggle);

            this.initSearchClient();

            this.addListeners();

            this.adjustHeight();
        }

        initSearchClient () {
            this.searchClient = new SearchClient(this.render);
            this.searchClient.form = this.form;
            this.searchClient.path = this.searchPath;
        }

        initMap () {
            this.map = new Map({
                element: this.mapContainer,
                search: this,
                callback: () => {
                    this.update(location.href);
                }
            });
        }

        addListeners () {
            if (Utils.hasHistory()) {
                this.form.addEventListener('change', this.formHandler);

                this.links = this.mainContainer.querySelectorAll(SELECTORS.trigger);
                Utils.forEach(this.links, (link) => {
                    link.addEventListener('click', this.formHandler);
                });
            }

            if (this.shouldHandleScrollHint) {
                this.sticky = new Sticky({
                    'el': this.newResultsHint,
                    'preventOverlap': {
                        'target': document.querySelector(SELECTORS.listingSearchFooter),
                        'margin': 40,
                    }
                });
            }

            this.sortDirection && this.sortDirection.addEventListener('click', this.sortHandler);

            Utils.forEach(this.modals, function (el) {
                HW.app.modal.addListener(el);
            });

            HW.app.modal.enableListeners();

            Utils.forEach(this.form.querySelectorAll(FormSelect.getSelector()), (el) => {
                let select = new FormSelect({el: el});
                this.unbinders.push(select);
            });

            if (this.listings && !this.firstInit) {
                // at first async forms are instantiated by app, so skip this the first time
                Utils.forEach(this.listings.querySelectorAll(Form.getSelector()), (form) => {
                    let favorite = new Form({form: form});
                    this.unbinders.push(favorite);
                });

                Utils.forEach(this.listings.querySelectorAll(PreviewSlideshow.getSelector()), (el) => {
                    let slideshow = new PreviewSlideshow({el: el});
                    this.unbinders.push(slideshow);
                });
            }

            Utils.forEach(this.mainContainer.querySelectorAll(Collapse.getSelector()), (el) => {
                let collapse = new Collapse({
                    el: el,
                    callback: this.adjustHeight
                });
                this.unbinders.push(collapse);
            })

            this.firstInit = false;
        }

        removeListeners () {
            if (Utils.hasHistory()) {
                this.form.removeEventListener('change', this.formHandler);

                this.links = this.mainContainer.querySelectorAll(SELECTORS.trigger);
                Utils.forEach(this.links, (link) => {
                    link.removeEventListener('click', this.formHandler);
                });
            }

            this.sticky && this.sticky.dispose();

            this.sortDirection && this.sortDirection.removeEventListener('click', this.sortHandler);

            Utils.forEach(this.modals, function (el) {
                HW.app.modal.removeListener(el);
            });

            this.unbinders.map((unbinder) => {
                unbinder.dispose();
                this.unbinders = [];
            });
        }

        formHandler = (e) => {
            e.preventDefault();
            let endpoint = e.target.href || null;

            this.update(endpoint, e);
        }
        update = (url = null, e = null, args = null) => {
            this.hideHint();
            this.showLoaders();
            this.scroll(e);
            this.searchClient.fetch(url, e, args);
        }
        render = (data, event, url) => {
            if (data) {
                HW.app.navigate(url, {trigger: false});

                if (data.meta) {
                    this.renderMetaData(data.meta);
                }

                if (data.breadcrumbs_html) {
                    this.renderBreadcrumbs(data.breadcrumbs_html);
                }

                if (data.results) {
                    this.renderMarkers(data.results);
                }

                if (data.listings_html) {
                    this.renderListings(data.listings_html);
                    this.renderPagination(data.pagination_html);
                }

                this.renderFiltersAside(data.filters_aside_html);

                this.renderSearchListHeader(data.search_list_header);
                this.renderActionBar(data.action_bar);

                if (data.count > 0) {
                    this.renderScrollHint(data.count);
                }

                this.adjustHeight();
                gtag('set', 'page_location', document.location.href);
                gtag('set', 'page_path', url);
                gtag('event', 'page_view');
            } else {
                this.hideLoaders();
            }
            this.removeListeners();
            this.init();
        }

        renderMarkers (properties) {
            if (this.map && properties.length != 0) {
                this.map.displayProperties(properties);
            }
        }

        renderListings (html) {
            if (null == this.listings) {
                return;
            }

            this.listings.innerHTML = html;
        }

        renderSearchListHeader (html) {
            if (null == this.searchListHeader) {
                return;
            }

            this.searchListHeader.innerHTML = html;
        }

        renderActionBar (html) {
            if (null == this.actionBar) {
                return;
            }

            this.actionBar.innerHTML = html;
        }

        renderPagination (html) {
            if (null == this.pagination) {
                return;
            }

            this.pagination.innerHTML = html;
        }

        renderFiltersAside (html) {
            this.filtersAside.innerHTML = html;
        }

        renderMetaData (meta) {
            if (null == this.pageHeaderTitle) {
                return;
            }

            this.pageHeaderTitle.innerHTML = meta.page_header_title;
            this.pageHeaderIntro.innerHTML = meta.page_header_intro;
            document.title = meta.meta_title;
            document.querySelector('meta[name="description"]').setAttribute('content', meta.meta_description);
            document.querySelector('link[rel="canonical"]').setAttribute('href', meta.canonical_url);
        }

        renderBreadcrumbs (html) {
            if (null == this.breadcrumbs) {
                return;
            }

            this.breadcrumbs.innerHTML = html;
        }

        adjustHeight = () => {
            // don't adjust height when we're in mobile view
            if (!this.listings || Utils.isMobile()) {
                return;
            }
            let minHeight = this.filtersAside.parentElement.offsetHeight;
            this.listings.style.minHeight = `${minHeight}px`;
        }
        sortHandler = (e) => {
            const button = e.target;
            const buttonWrapper = button.parentNode;

            if (buttonWrapper.getAttribute(ATTRIBUTES.sort) != ATTRIBUTES.sortButton) {
                return;
            }

            let directionAscInput = this.form.querySelector(SELECTORS.directionAsc);
            let directionDescInput = this.form.querySelector(SELECTORS.directionDesc);

            if (Utils.hasClass(buttonWrapper, CLASSES.directionAsc)) {
                Utils.removeClass(buttonWrapper, CLASSES.directionAsc);
                Utils.addClass(buttonWrapper, CLASSES.directionDesc);
                directionDescInput.click();
            } else {
                Utils.removeClass(buttonWrapper, CLASSES.directionDesc);
                Utils.addClass(buttonWrapper, CLASSES.directionAsc);
                directionAscInput.click();
            }
        }
        showLoaders = () => {
            HW.app.modal.disableListeners();
            if (this.listings) {
                Utils.forEach(this.listings.querySelectorAll(SELECTORS.listing), (listing) => {
                    Utils.addClass(listing, CLASSES.listingLoading);
                });
            }
        }
        hideLoaders = () => {
            if (this.listings) {
                Utils.forEach(this.listings.querySelectorAll(SELECTORS.listing), (listing) => {
                    Utils.removeClass(listing, CLASSES.listingLoading);
                });
            }
        }

        scroll (e) {
            if (!e) {
                return;
            }

            let el = e.target;
            if (el.hasAttribute(SELECTORS.scroll) || this.isMapView) {
                scrollToTop(this.scrollTargetY);
            }
        }

        renderScrollHint (count) {
            if (!this.shouldHandleScrollHint) {
                return;
            }

            let last = document.getElementById(SELECTORS.lastListing) || document.getElementById(SELECTORS.firstListing);
            let height = last.offsetHeight;
            let bottom = Utils.getOffsetTop(last) + height;
            if (bottom < 200) {
                this.showHint(count);
            } else {
                this.hideHint(count);
            }
        }

        showHint (count) {
            if (!this.shouldHandleScrollHint) {
                return;
            }

            this.newResultsHint.addEventListener('click', this.showResults);
            Utils.text(this.newResultsCount, count);
            this.newResultsHint.style.width = `${document.getElementById(SELECTORS.firstListing).getBoundingClientRect().width}px`;
            Utils.removeClass(this.newResultsHint, CLASSES.hideHint);
        }

        hideHint () {
            if (!this.shouldHandleScrollHint) {
                return;
            }

            this.newResultsHint.removeEventListener('click', this.showResults);
            Utils.addClass(this.newResultsHint, CLASSES.hideHint);
        }

        showResults = () => {
            scrollToTop(this.scrollTargetY);
            this.hideHint();
        }
        setGeoFields = (bounds, zoom) => {
            document.getElementById('map_zoom_hidden').value = zoom;
            document.getElementById('bounds_hidden').value = bounds;
        }

        get shouldHandleScrollHint () {
            if (Utils.isMobile()) {
                return false;
            }

            if (this.isMapView) {
                return false;
            }

            return true;
        }

        set results (data) {
            this._results = data;
        }

        get results () {
            return this._results;
        }

        set url (url) {
            this._url = url;
        }

        get url () {
            return this._url;
        }

        get scrollTargetY () {
            return Utils.isMobile() ? 0 : this.header.offsetHeight;
        }

        get searchPath () {
            if (this.isMapView) {
                return HW.paths.listing_search_map;
            }

            if (this.isRequestsView) {
                return HW.paths.listing_request_search;
            }

            return HW.paths.listing_search;
        }
    }

    return Search;
})();

module.exports = Search;
