// port of require.js async loader plugin
// use for externally loading js, like google api's
class AsyncScriptLoader {
  constructor(name, cb, call = false) {
    this._uid = 0
    this.load(name, cb, call)
  }
  load(name, cb, call) {
    let id = this.uid
    window[id] = cb
    this.injectScript(this.formatUrl(name, id), cb, call)
  }
  injectScript(src, cb, call) {
    let s = document.createElement("script")
    let t = document.getElementsByTagName("script")[0]

    s.type = "text/javascript"
    s.async = true
    s.src = src

    t.parentNode.insertBefore(s, t)

    if (call) {
      if (s.readyState) { // IE
        s.onreadystatechange = function() {
          if (s.readyState == "loaded" || s.readyState == "complete") {
            s.onreadystatechange = null
            if (typeof cb == "function") {
              return cb()
            }
          }
        }
      } else {
        s.onload = function() {
          s.onload = null
          if (typeof cb == "function") {
            return cb()
          }
        }
      }
    }
  }
  formatUrl(name, id) {
    let paramRegex = /!(.+)/
    let url = name.replace(paramRegex, "")
    let param = paramRegex.test(name) ? name.replace(/.+!/, "") : "callback"
    url += url.indexOf("?") < 0 ? "?" : "&"
    return `${url}${param}=${id}`
  }
  get uid() {
    this._uid += 1
    return `__async_${this._uid}"__"`
  }
}

module.exports = AsyncScriptLoader
