import Utils from 'utils';

const Sticky = (() => {
  const ATTRIBUTES = {
    'sticky': 'data-sticky',
    'target': 'data-sticky-target',
    'direction': 'data-sticky-direction',
  }
  const SELECTORS = {
    'sticky': `[${ATTRIBUTES.sticky}]`,
    'target': `[${ATTRIBUTES.target}]`,
    'direction': `[${ATTRIBUTES.direction}]`,
  }
  const DIRECTIONS = {
    'up': 'up',
    'down': 'down',
  }

  class Sticky {
    constructor(options = {}) {
      if (!options.el) {
        return;
      }

      this.el           = options.el;
      this.offset       = 0;
      this.targetOffset = 0;

      this.ticking      = false;

      if (options.preventOverlap && options.preventOverlap.target) {
        this.preventOverlap = options.preventOverlap;
      }

      this.init();
    }
    init() {
      if (!this.target) {
        return;
      }

      Utils.addClass(document.body, this.id);
      this.offset = Utils.getOffsetTop(this.el);
      this.targetOffset = Utils.getOffsetTop(this.target);

      window.addEventListener('scroll', this.onScroll, false);

      this.update();

      // give the render engine time to update the DOM before positioning the element
      window.setTimeout(() => {
        this.preventOverlapping();
      }, 0);
    }
    onScroll = (e) => {
      this.targetOffset = Utils.getOffsetTop(this.target);
      this.requestTick();
    }
    requestTick() {
      if (!this.ticking) {
        requestAnimationFrame(this.update);
        this.ticking = true;
      }
    }
    update = () => {
      if (this.comparator) {
        Utils.addClass(document.body, this.stickyClass);
      } else {
        Utils.removeClass(document.body, this.stickyClass);
      }

      this.preventOverlapping();

      this.ticking = false;
    }
    dispose() {
      Utils.removeClass(document.body, [this.stickyClass, this.id]);
      window.removeEventListener('scroll', this.onScroll);
    }
    preventOverlapping = () => {
      if (!this.preventOverlap) {
        return;
      }

      this.el.style.top = '';

      const stickyElDims = this.el.getBoundingClientRect();
      const overlapElDims = this.preventOverlap.target.getBoundingClientRect();

      if (this.direction == DIRECTIONS.up) {
        if (overlapElDims.top - this.preventOverlap.margin < stickyElDims.bottom) {
          this.el.style.top = (overlapElDims.top - stickyElDims.height - this.preventOverlap.margin) + 'px';
        }
      } else if (direction == DIRECTIONS.down) {
        if (stickyElDims.top - this.preventOverlap.margin < overlapElDims.bottom) {
          this.el.style.top = (overlapElDims.bottom + this.preventOverlap.margin) + 'px';
        }
      }
    }
    get id() {
      return this.el.id;
    }
    get target() {
      let id = this.el.getAttribute(ATTRIBUTES.target);
      return document.getElementById(id);
    }
    get direction() {
      return this.el.hasAttribute(ATTRIBUTES.direction) ? this.el.getAttribute(ATTRIBUTES.direction) : DIRECTIONS.up;
    }
    get stickyClass() {
      return `${this.id}--unfix`;
    }
    get comparator() {
      if (this.direction == DIRECTIONS.up) {
        return this.targetOffset > this.offset;
      } else {
        return this.targetOffset < this.offset;
      }
    }
  }

  return Sticky;
})();

module.exports = Sticky;
