import Fetch from 'utils/fetch';
import Utils from 'utils';

const FavoriteMailAlertHandler = (() => {
  const ATTRIBUTES = {
    'trigger': 'favorite',
    'modalName': 'mail-alert',
  }

  class FavoriteMailAlertHandler {
    constructor(options = {}) {
      this.init();
    }
    init() {
      HW.app.events.on('form:success', this.formSuccesHandler);
      HW.app.events.on('modal:closed', this.modalClosedHandler);
    }
    formSuccesHandler = (data) => {
      if (this.modalShouldRender(data)) {
        HW.app.modal.open(ATTRIBUTES.modalName, data.show_mail_alert_url, ATTRIBUTES.trigger);
      }
    }
    modalClosedHandler = (args) => {
      if (this.cookieShouldBeSet(args)) {
        let fetchArgs = {
          method: 'POST',
        }

        Fetch(HW.paths.hide_favorite_mail_alert, Utils.noop, fetchArgs);
      }
    }
    modalShouldRender(data) {
      // set via controller json response
      if (!data.show_mail_alert_url) {
        return false;
      }

      return true;
    }
    cookieShouldBeSet(data) {
      // set via formSuccesHandler
      if ('favorite' != data.trigger) {
        return false;
      }
      // set via FormModal class
      // true if on first step of mail alert modal, succes modal name will be mail-alert-success
      if ('mail-alert' != data.form_data.name) {
        return false;
      }

      return true;
    }
  }

  return FavoriteMailAlertHandler;
})();

module.exports = FavoriteMailAlertHandler;
