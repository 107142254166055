import Utils from 'utils';

const PreviewSlideshow = (() => {
  const ATTRIBUTES = {
    'preview_slideshow': 'data-preview-slideshow',
  };

  const CLASSES = {
    'track': 'preview-slideshow__track',
    'item': 'preview-slideshow__item',
    'next': 'preview-slideshow__control--next',
    'previous': 'preview-slideshow__control--previous',
  };

  const SELECTORS = {
    'preview_slideshow': `[${ATTRIBUTES.preview_slideshow}]`,
    'track': `.${CLASSES.track}`,
    'item': `.${CLASSES.item}`,
    'next': `.${CLASSES.next}`,
    'previous': `.${CLASSES.previous}`,
  };

  class PreviewSlideshow {
    constructor(options) {
      if (!options.el) {
        throw new Error('"options.el" is not defined');
      }

      this.el = options.el;
      this.durationPerItem = options.durationPerItem || 500;

      this.track = this.el.querySelector(SELECTORS.track);
      this.nextBtn = this.el.querySelector(SELECTORS.next);
      this.previousBtn = this.el.querySelector(SELECTORS.previous);
      this.items = this.el.querySelectorAll(SELECTORS.item);
      this.currentIndex = 0;
      this.previousBtn.disabled = true;

      this.init();

      this.checkLazyLoad();
    }

    init = () => {
      this.addEventListeners();
    }

    addEventListeners = () => {
      this.nextBtn.addEventListener('click', this.nextBtnClick);
      this.previousBtn.addEventListener('click', this.previousBtnClick);

      window.addEventListener('scroll', this.checkLazyLoad, {passive: true});
    }

    removeEventListeners = () => {
      this.nextBtn.removeEventListener('click', this.nextBtnClick);
      this.previousBtn.removeEventListener('click', this.previousBtnClick);
    }

    nextBtnClick = (evt) => {
      this.moveNext();

      evt.preventDefault();
      evt.stopPropagation();
    }

    previousBtnClick = (evt) => {
      this.movePrevious();

      evt.preventDefault();
      evt.stopPropagation();
    }

    checkLazyLoad = () => {
      const scrollTop = Utils.getScrollY();
      const offsetTop = Utils.getOffset(this.el).top;
      const bottomEdge = scrollTop + window.innerHeight;

      if (offsetTop < bottomEdge) {
        this.loadImage(0);
      }
    }

    moveNext = () => {
      const newIndex = this.currentIndex + 1;

      this.goTo(newIndex);
      this.loadImage(newIndex);
    }

    movePrevious = () => {
      const newIndex = this.currentIndex - 1;

      this.goTo(newIndex);
      this.loadImage(newIndex);
    }

    goTo = (index) => {
      const jump = this.currentIndex - index;

      if (jump == 0) {
        return;
      }

      const transitionEnd = () => {
        this.currentIndex = index;

        this.enableButtons();

        this.track.removeEventListener('transitionend', transitionEnd);
      };

      this.track.addEventListener('transitionend', transitionEnd);

      const duration = jump * this.durationPerItem;
      const newOffset = (100 / this.items.length) * index;

      const transitionDurationProperty = Utils.getPrefixedCSSProperty('transition-duration', 'transitionDuration');
      const transformProperty = Utils.getPrefixedCSSProperty('transform', 'transform');

      this.track.style[transitionDurationProperty.js] = `${duration < 0 ? duration * -1 : duration}ms`;
      this.track.style[transformProperty.js] = `translate3d(-${newOffset}%, 0, 0)`;

      this.disableButtons();
    }

    disableButtons = () => {
      this.nextBtn.disabled = true;
      this.previousBtn.disabled = true;
    }

    enableButtons = () => {
      if (this.currentIndex > 0) {
        this.previousBtn.disabled = false;
      }

      if (this.currentIndex < this.items.length - 1) {
        this.nextBtn.disabled = false;
      }
    }

    loadImage = (index) => {
      const item = this.items[index];

      if (!item) {
        return;
      }

      const image = this.items[index].querySelector('img');

      if (image && image.src === '') {
        image.src = image.getAttribute('data-src');
        image.srcset = image.getAttribute('data-srcset');
      }
    }

    dispose = () => {
      this.removeEventListeners();
    }

    static getSelector() {
      return SELECTORS.preview_slideshow;
    }
  }

  return PreviewSlideshow;
})();

export default PreviewSlideshow;
