import Utils from 'utils'

const Menu = (() => {
  const ATTRIBUTES = {
    'menu': 'data-menu'
  }
  const CLASSES = {
    'visible': 'menu--visible',
    'hidden': 'menu--hidden',
    'toggled': 'toggled',
  }
  const SELECTORS = {
    'menu': `[${ATTRIBUTES.menu}]`,
  }

  class Menu {
    constructor(options = {}) {
      this.el = options.el || document.querySelector(SELECTORS.menu)

      let targetId = this.el.getAttribute(`${ATTRIBUTES.menu}`)

      if (targetId) {
        this.target = document.getElementById(targetId);
      } else {
        this.target = this.el.parentElement
      }

      this.toggled = false

      this.init()
    }
    init() {
      Utils.addClass(this.target, CLASSES.hidden)

      // click inside dropdown should toggle menu
      this.el.addEventListener('click', this.toggle)

      // click outside dropdown should close the menu
      document.addEventListener('click', this.onClickOutside);
    }
    onClickOutside = (evt) => {
      if (!this.toggled) {
        return;
      }

      const clickedElementIsMenu = evt.target === this.el;
      if (clickedElementIsMenu) {
          return;
      }

      const menuIsParentOfClicked = Utils.closestParent(evt.target, this.target) === this.target;
      if (menuIsParentOfClicked) {
          return;
      }

      this.close();
    }
    toggle = (e) => {
      e.preventDefault()

      if (this.toggled) {
        this.close(e)
      } else {
        this.open(e)
      }
    }
    open = (e) => {
      Utils.addClass(this.el, CLASSES.toggled)
      Utils.addClass(this.target, CLASSES.visible)
      Utils.removeClass(this.target, CLASSES.hidden)
      this.toggled = true
    }
    close = (e) => {
      Utils.removeClass(this.el, CLASSES.toggled)
      Utils.addClass(this.target, CLASSES.hidden)
      Utils.removeClass(this.target, CLASSES.visible)
      this.toggled = false
    }
    dispose() {
      this.el.removeEventListener('click', this.toggle)
    }
    static getSelector() {
      return SELECTORS.menu
    }
  }

  return Menu
})()

module.exports = Menu
