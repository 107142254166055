import FormModal from 'utils/form-modal';

const SearchModal = (() => {
  class SearchModal extends FormModal {
    constructor(options = {}) {
      super(options);
    }
  }

  return SearchModal;
})();

export default SearchModal;
