import FormModal from 'utils/form-modal';
import Utils from 'utils';

const MailAlertModal = (() => {
  const SUCCESS_MODAL_NAME = 'mail_alert_success';

  const SELECTORS = {
    'id': 'mail-alert',
    'trigger': '[data-modal="mail-alert"]',
  }

  class MailAlertModal extends FormModal {
    init(options = {}) {
      super.init(options);

      this.label = null;

      if (null !== options.label) {
        this.label = Utils.urldecode(options.label);
      }
    }
    formHandler(data) {
      // handle when success modal is returned
      if (data.name == SUCCESS_MODAL_NAME) {
        if ('reload' in data && data.reload === true) {
          this.modal.closeHandler = () => {
            window.location.href = window.location;
          }
        }
      }
    }
    static getSelector() {
      return SELECTORS.trigger;
    }
    static getId() {
      return SELECTORS.id;
    }
  }

  return MailAlertModal;
})();

export default MailAlertModal;
