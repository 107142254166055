import 'whatwg-fetch'
import Utils from 'utils'

export const Fetch = function(endpoint, callback = Utils.noop, {
      method: method,
      id: id,
      body: body,
      redirect: redirect,
      authorize: authorize,
      transform: transform,
      headers: headers,
    } = {
      method: 'GET',
      id: null,
      body: null,
      redirect: null,
      authorize: null,
      transform: null,
      headers: {},
    }
) {
  const HEADERS = new Headers({
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded',
     ...headers
  })
  const fetchInit = {
    method: method,
    headers: HEADERS,
    credentials: 'include'
  }

  if (body) {
    if (transform) {
      body = transform(body)
    }
    fetchInit['body'] = body
  }

  const handleRedirect = function(url) {
    location.href = url
  }

  const handleAuthorization = function(response) {
    // trigger global unauthorized event
    HW.app.events.trigger('unauthorized')
    // opens authorization modal
    return HW.app.openAuthModal(response)
  }

  const fetchRequest = new Request(endpoint, fetchInit)

  const checkStatus = function(response) {
    if (response.status >= 200 && response.status < 300) {
      return response
    } else if (response.status == 401) {
      callback = authorize || function authorize(response) {
        handleAuthorization(response)
      }
      return response
    } else {
      let error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }

  const parseContent = function(response) {
    if (response.headers.get('Content-Type') == 'application/json') {
      return response.json()
    } else {
      return response.text()
    }
  }

  const checkRedirect = function(data) {
    if (data.redirect) {
      data = data.redirect
      callback = redirect || handleRedirect
    }

    return data
  }

  const checkRefresh = function(data) {
    if (data.refresh) {
      location.reload()
    }

    return data
  }

  fetch(fetchRequest)
  .then(checkStatus)
  .then(parseContent)
  .then(checkRefresh)
  .then(checkRedirect)
  .then(function(data) {
    return callback(data, id)
  })
}

module.exports = Fetch;
