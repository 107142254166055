import FilterModal from './filter';
import SearchModal from './search';
import MailAlertModal from './mail_alert';
import ListingUnpublishModal from './listing_unpublish';
import LoginModal from './login';
import RegisterModal from './register';
import AuthorizeModal from './authorize';
import ReportModal from './report';
import ConsentModal from './consent';

let activeModal = null;

let modals = {
    'filter': FilterModal,
    'search': SearchModal,
    'mail-alert': MailAlertModal,
    'listing-unpublish': ListingUnpublishModal,
    'login': LoginModal,
    'register': RegisterModal,
    'authorize': AuthorizeModal,
    'report': ReportModal,
    'consent': ConsentModal,
};

export function load (modalName, modalArgs = null, disposeActive = true) {
    disposeActive && unload(modalArgs);

    let modal = modals[modalName];

    if (modal) {
        if (typeof modal === 'function') {
            modal = new modal(modalArgs);
            modals[modalName] = modal;
        } else {
            modal.init(modalArgs);
        }
        activeModal = modal;

        HW.app.events.trigger('modal:open', modalArgs);
    } else {
        activeModal = {
            dispose: function () {
            }
        }
    }
}

export function unload (modalArgs) {
    if (activeModal) {
        HW.app.events.trigger('modal:closed', modalArgs);
        activeModal.dispose(modalArgs);
        activeModal = null;
    }
}
