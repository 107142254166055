import {ComponentController} from 'helpers/stimulus/component-controller';
import {renderClasses} from 'helpers/bem/bem';

const NAME = 'search-map';

const MESSAGE_HANDLERS = [
    'mapMarkerClicked.all',
    'mapClicked.all',
];

const CLASSES = {
    'listingSummary': renderClasses(NAME, 'listing-summary'),
};

const SELECTORS = {
    'listingSummary': `.${CLASSES.listingSummary}`,
};

class SearchMap extends ComponentController {
    connect () {
        this.cache = {};

        super.connect();
    }

    async onMapMarkerClicked (evt) {
        const id = evt.data.data.id;
        const listingSummaryContainer = this.element.querySelector(SELECTORS.listingSummary);

        if (!this.cache.hasOwnProperty(id)) {
            this.messageBus.postMessage({
                'message': 'searchMapListingRenderStarted',
            });

            listingSummaryContainer.innerHTML = null; // empty container where template is mounted on

            const response = await fetch(`/listing-summary/${id}/`);
            let status = await response.status;
            let template = await response.text();

            if (status > 400) {
                console.log('Foutje bedankt!');
            }

            this.cache[id] = template;
        }

        listingSummaryContainer.innerHTML = this.cache[id];

        this.messageBus.postMessage({
            'message': 'searchMapListingRenderEnded',
        });
    }

    onMapClicked () {
        const listingSummaryContainer = this.element.querySelector(SELECTORS.listingSummary);
        listingSummaryContainer.innerHTML = null; // empty container where template is mounted on
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': SearchMap
};
