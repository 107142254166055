import Utils from 'utils';

(function () {
  'use strict';

  /*
   * Convenience function that takes an root element from which it will find and initialize tab components on its own
   *
   * @param HTMLElement The root element
   */
  function walk (root) {
    if (!root) {
      return;
    }
    var tabs = root.querySelectorAll('.tabs');

    tabs.forEach(init);
  }


  /*
   * Factory function that initializes the behaviour (mouse and keyboard) of the tab controls
   * Returns an object with the element on which it was initialized and a public API
   *
   * @param HTMLElement The top most element of the tab component
   *
   * @return Object
   */
  function init (tabContainer) {
    var tabList = tabContainer.querySelector('[role=tablist]');

    function setPanelActive (index) {
      var panels = tabContainer.querySelector('.tabs__panels').children;
      var activePanel = panels[index];

      Utils.forEach(panels, function (panel) {
        var isActivePanel = panel === activePanel;

        if (isActivePanel) {
          Utils.removeClass(panel, 'is-inactive');
          panel.setAttribute('aria-hidden', 'false');

          panel.focus();
        } else {
          Utils.addClass(panel, 'is-inactive');
          panel.setAttribute('aria-hidden', 'true');
        }
      });
    }

    function setTabActive (index) {
      var tabList = tabContainer.querySelector('[role=tablist]');
      var tabs = tabList.querySelectorAll('[role=tab]');
      var activeTab = tabs[index];

      tabs.forEach(function (tab) {
        var isActiveTab = tab === activeTab;

        if (isActiveTab) {
          Utils.removeClass(tab, 'is-inactive');
          tab.setAttribute('tabindex', 0);
          tab.setAttribute('aria-selected', 'true');
          tab.setAttribute('aria-describedby', tabContainer.querySelector('.tabs__description').id);
        } else {
          Utils.addClass(tab, 'is-inactive');
          tab.setAttribute('tabindex', -1);
          tab.setAttribute('aria-selected', 'false');
          tab.removeAttribute('aria-describedby');
        }
      });
    }

    function selectTab (tab) {
      var index;

      index = Utils.getPreviousSiblings(tab.parentNode).length;

      setTabActive(index);
      setPanelActive(index);
    }

    setTabActive(0);
    setPanelActive(0);

    tabList.addEventListener('click', function (evt) {
      var activeTab;
      var ancestor = evt.target;

      evt.preventDefault();

      while (ancestor) {
        if (ancestor === document) {
          break;
        } else if (ancestor.getAttribute('role') === 'tab') {
          activeTab = ancestor;
          break;
        }

        ancestor = ancestor.parentNode;
      }

      if (activeTab) {
        selectTab(activeTab);
      }
    });

    tabList.addEventListener('keyup', function (evt) {
      var keyCode = evt.which || evt.keyCode;
      var activeTab = tabList.querySelector('[aria-selected=true]');
      var nextParentSibling = activeTab.parentNode.nextElementSibling;
      var previousElementSibling = activeTab.parentNode.previousElementSibling;

      switch (keyCode) {
        case 37: //left
          if (previousElementSibling) {
            selectTab(previousElementSibling.querySelector('[role=tab]'));
          }
          break;

        case 39: //right
          if (nextParentSibling) {
            selectTab(nextParentSibling.querySelector('[role=tab]', nextParentSibling));
          }
          break;
      }
    });

    return {
      element: tabContainer,
      select: selectTab
    };
  }

  module.exports = {
    walk: walk,
    init: init
  };
})();
