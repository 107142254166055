const setHideMailAlertBannerCookie = (() => {
  const COOKIE_NAME = 'hide_mail_alert_banner';
  const HOST_NAME = document.location.hostname;
  const COOKIE_VALUE = '1';

  const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

  const EXPIRES = expires.toUTCString();

  const setHideMailAlertBannerCookie = function () {
    document.cookie = `${COOKIE_NAME}=1;domain=${HOST_NAME};expires=${EXPIRES};path=/`;
  };

  return setHideMailAlertBannerCookie;
})();

export default setHideMailAlertBannerCookie;
