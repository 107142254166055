import Utils from 'utils';
import FormPassword from 'form/password';

const FormInput = (() => {
  const CLASSES = {
    'active': 'active',
    'focus': 'focus',
    'error': 'error',
  }
  const ATTRIBUTES = {
    'label': 'data-form-label'
  }
  const SELECTORS = {
    'input': '[data-form-control="text"]',
    'label': `[${ATTRIBUTES.label}]`,
  }

  class FormInput {
    constructor(options = {}) {
      this.el = options.el;
      this.parentEl = this.el.parentNode;
      this.formPassword = null;
      this.label = null;
      this.init();
    }
    init() {
      this.label = this.getLabel();
      this.el.addEventListener('focus', this.activate);
      this.el.addEventListener('input', this.activate);
      this.el.addEventListener('change', this.activate);
      this.el.addEventListener('blur', this.deactivate);
      this.checkIfAutofilled();
      this.checkIfPassword();
    }
    checkIfAutofilled() {
      setTimeout(() => {
        if (this.el.value != "" && null !== this.label) {
          Utils.addClass(this.parentEl, CLASSES.active);
        }
      }, 200);
    }
    checkIfPassword() {
      if (this.el.type == 'password') {
        this.formPassword = new FormPassword({el: this.el, parentEl: this.parentEl});
      }
    }
    activate = (e) => {
      if (e.type == 'input') {
        if (this.el.value != '') {
          Utils.removeClass(this.parentEl, CLASSES.error);
        }
      }

      if (null === this.label) {
        return;
      }

      if (this.el.type == 'file' && this.label) {
        let fileName = e.target.value.split( '\\' ).pop();

        this.label.innerHTML = fileName ? fileName : this.label.getAttribute(ATTRIBUTES.label);
      }

      Utils.addClass(this.parentEl, CLASSES.active);
      Utils.addClass(this.parentEl, CLASSES.focus);
    }
    deactivate = () => {
      Utils.removeClass(this.parentEl, CLASSES.focus);
      if (this.el.value == '' && this.el.placeholder == '') {
        Utils.removeClass(this.parentEl, CLASSES.active);
      }
    }
    dispose() {
      this.el.removeEventListener('focus', this.activate);
      this.el.removeEventListener('input', this.activate);
      this.el.removeEventListener('change', this.activate);
      this.el.removeEventListener('blur', this.deactivate);
      this.formPassword && this.formPassword.dispose();
    }
    getLabel() {
      let label = null;
      if (label = this.parentEl.querySelector(SELECTORS.label)) {
        return label;
      }

      label = this.parentEl.querySelector(`[for="${this.id}"]`);
      return label;
    }
    get id() {
      return this.el.id || null;
    }
    static getSelector() {
      return SELECTORS.input;
    }
    static getActiveClass() {
      return CLASSES.active;
    }
  }

  return FormInput;
})()

module.exports = FormInput;
