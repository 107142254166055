import {ComponentController} from 'helpers/stimulus/component-controller';

const NAME = 'listing-contact-info';

class ListingContactInfo extends ComponentController {
    initialize () {
        this.onClick = this.onClick.bind(this);

        super.initialize();
    }

    onClick (evt) {
        const element = evt.target;
        const phoneNumber = this.data.get('phone');

        if (element.innerText !== phoneNumber) {
            element.innerText = phoneNumber;
            element.style.cursor = 'auto';
        }
    }
}

export default {
    'name': NAME,
    'controller': ListingContactInfo
};
