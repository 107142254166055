import Utils from 'utils'
import FormSelect from 'form/select'
import FormChoice from 'form/choice'

const FormSelectOptgroups = (() => {
  const SELECTORS = {
    'select': '[data-form-control="select-optgroups"]',
  }
  const BEM = {
    'controlBlock': 'control-group',
    'buttonsModifier': '--buttons',
    'hiddenModifier': '--hidden',
    'selectModifier': '--select',
  }
  const CLASSES = {
    'active': 'active',
    'error': 'error',
    'controlHidden': `${BEM.controlBlock}${BEM.hiddenModifier}`,
    'controlBlock': `${BEM.controlBlock}`,
    'controlButtons': `${BEM.controlBlock}${BEM.buttonsModifier}`,
    'controlSelect': `${BEM.controlBlock}${BEM.selectModifier}`,
  }
  const ATTRIBUTES = {
    'dataControlMapping': 'data-form-control-mapping',
    'dataControlValidation': 'data-form-control-validation',
    'dataControlPlaceholder': 'data-form-control-placeholder',
    'dataControlModifier': 'data-form-control-modifier',
  }

  class FormSelectOptgroups {
    constructor(options = {}) {
      this.el = options.el
      this.container = null
      this.choiceContainer = null
      this.form = null
      this.hiddenInput = null
      this.mappedInput = null
      this.mappedInputId = null
      this.mappedInputName = null
      this.modifier = null

      this.groups = []
      this.radios = []
      this.selects = []

      this.init()
    }
    init() {
      this.container = this.el.parentElement
      this.form = this.el.form
      this.modifier = this.el.getAttribute(ATTRIBUTES.dataControlModifier) || ''
      this.mappedInputId = this.el.getAttribute(ATTRIBUTES.dataControlMapping) || this.id.replace('subtype', 'type');
      this.mappedInput = document.getElementById(this.mappedInputId)
      this.mappedInputName = this.mappedInput.name

      this.choiceContainer = Utils.createEl('div', ['choice-buttons', `choice-buttons--${this.modifier}`])
      this.container.appendChild(this.choiceContainer)

      this.renderControls()
    }
    renderControls() {
      this.groups = this.el.getElementsByTagName('optgroup')
      this.renderMappingControls()

      Utils.forEach(this.groups, (el) => {
        this.radios.push(this.renderRadio(el))
      })
      Utils.forEach(this.groups, (el) => {
        this.selects.push(this.renderSelect(el))
      })
      this.radios.map((radio) => {
        radio.addEventListener('change', this.toggleSelects)
      })
      this.container.removeChild(this.el)
      Utils.removeClass(this.container, [CLASSES.controlSelect, CLASSES.active])
      Utils.addClass(this.container, CLASSES.controlButtons)
    }
    toggleSelects = (e) => {
      Utils.removeClass(this.container, CLASSES.error)
      this.selects.map((select) => {
        this.hiddenInput.value = ''
        Utils.addClass(select.parentNode, CLASSES.controlHidden)
        Utils.removeClass(select.parentNode, CLASSES.active)
        select.value = ''
        select.options[0].innerHTML = select.parentElement.firstChild.innerHTML
      })

      // show linked select
      let radio = e.currentTarget
      let activeSelect = document.getElementById(`${radio.id}_select_container`)
      Utils.toggle(activeSelect, CLASSES.controlHidden)
    }
    renderMappingControls() {
      this.hiddenInput = document.createElement('input')
      this.hiddenInput.type = 'hidden'
      this.hiddenInput.name = this.el.name
      this.form.appendChild(this.hiddenInput)

      this.mappedInput.parentNode.removeChild(this.mappedInput)
    }
    renderRadio(el) {
      let value = this.getValue(el)
      let id = this.makeId(el, value)

      let choice = new FormChoice({
        id: id,
        label: el.label,
        value: value,
        container: this.choiceContainer,
        name: this.mappedInputName,
        type: 'radio',
        modifier: 'button',
        checked: this.mappedInput.value == value
      })

      return choice.el
    }
    renderSelect(el) {
      let value = this.getValue(el)
      let id = this.makeId(el, value)
      let selectId = `${id}_select`
      let radio = document.getElementById(id)
      let labelFormat = this.el.getAttribute(ATTRIBUTES.dataControlPlaceholder) ? this.el.getAttribute(ATTRIBUTES.dataControlPlaceholder) : '%type%'
      let label = labelFormat.replace('%type%', el.label.toLowerCase())
      let select = new FormSelect({
        options: el.children,
        id: selectId,
        label: label,
        container: this.container,
        extra_label: this.el.getAttribute(ATTRIBUTES.dataControlValidation),
      })

      if (select.isSelected || radio.checked) {
        this.hiddenInput.value = this.el.value
        Utils.removeClass(select.parentEl, CLASSES.controlHidden)
      } else {
        Utils.addClass(select.parentEl, CLASSES.controlHidden)
      }

      select.el.addEventListener('change', (e) => {
        this.hiddenInput.value = e.currentTarget.value
      })

      return select.el
    }
    dispose = () => {
      return true
    }
    getValue(el) {
      return el.children[0].value.split('_')[0]
    }
    makeId(el, value) {
      return `${this.mappedInputId}_${value}`
    }
    get id() {
      return this.el.id;
    }
    static getSelector() {
      return SELECTORS.select
    }
  }

  return FormSelectOptgroups
})()

module.exports = FormSelectOptgroups
