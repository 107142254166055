import { ComponentController } from 'helpers/stimulus/component-controller';
import scriptCache from 'helpers/script-cache/script-cache';

const NAME = 'contact-form';

const ATTRS = {
    'recaptchaToken': `data-${NAME}-recaptcha-token`,
}

const SELECTORS = {
    'recaptchaToken': `[${ATTRS.recaptchaToken}]`,
}

class ContactForm extends ComponentController {
    initialize () {
        this.init = this.init.bind(this);
        this.token = HW.config.google_recaptcha_key;
    }
    connect () {
        const cache = scriptCache({
            'recaptcha': `//www.google.com/recaptcha/api.js?render=${this.token}`,
        });
        cache.recaptcha.onLoad(() => {
            return this.init();
        });
    }

    init () {
        const recaptchaElement = this.element.querySelector(SELECTORS.recaptchaToken);

        grecaptcha.ready(() => {
            grecaptcha.execute(this.token, {action: 'contact'}).then((token) => {
                recaptchaElement.value = token;
            });
        });
    }
};

export default {
    name: NAME,
    controller: ContactForm
};