import loadImage from 'blueimp-load-image';

import {renderClasses} from "helpers/bem/bem";
import {findOne} from "helpers/dom/dom";
import {ComponentController} from 'helpers/stimulus/component-controller';

const NAME = 'listing-photo-uploader';

const CLASSES = {
    'input': renderClasses(NAME, 'input'),
    'errors': renderClasses(NAME, 'errors'),
    'dragTarget': renderClasses(NAME, 'drag-target'),
    'dragTargetShow': renderClasses(NAME, 'drag-target', ['show']),
};

const SELECTORS = {
    'input': `.${CLASSES.input}`,
    'errors': `.${CLASSES.errors}`,
    'dragTarget': `.${CLASSES.dragTarget}`,
};

const ATTRS = {
    'endpoint': 'endpoint',
};

const MESSAGES = {
    'started': 'listingPhotoUploaderStarted',
    'ended': 'listingPhotoUploaderEnded',
    'failed': 'listingPhotoUploaderFailed',
};

const MESSAGE_HANDLERS = [
    'resetUploadInputField.all',
];

function imgToCanvas (img) {
    const canvas = document.createElement('canvas');
    const width = img.naturalWidth || img.width;
    const height = img.naturalHeight || img.height;

    canvas.width = width;
    canvas.height = height;

    canvas.getContext('2d').drawImage(img, 0, 0, width, height);

    return canvas;
}

class ListingPhotoUploader extends ComponentController {
    connect () {
        super.connect();

        this.queue = [];
        this.errors = [];
        this.filesInput = findOne(SELECTORS.input, this.element);
        this.endpoint = this.data.get(ATTRS.endpoint);
        this.maxFileSize = 5 * 1024 * 1024; // 5MB
        this.jpgQuality = 0.85;

        document.body.addEventListener('dragenter', this.onDragEnter.bind(this));
        document.body.addEventListener('dragexit', this.onDragExit.bind(this));

        this.dragTarget = findOne(SELECTORS.dragTarget, this.element);
        this.dragTarget.addEventListener('drop', this.onDrop.bind(this));

        // apparently you need to prevent default on every drag related event in order to be able to drop files
        const dragNodes = [document.body, this.dragTarget];

        const preventDefaultDrag = (evt) => {
            const target = evt.target;
            const targetIsSorterItem = target.classList.contains('listing-photo-sorter__item');

            if (!targetIsSorterItem) {
                evt.preventDefault();
            }
        };

        ['dragenter', 'dragstart', 'dragend', 'dragleave', 'dragover', 'drag', 'drop'].forEach(evtName => {
            dragNodes.forEach(node => {
                node.addEventListener(evtName, preventDefaultDrag);
            });
        });
    }

    onChange () {
        this.clearErrors();
        this.handleFiles([...this.filesInput.files]);
    }

    onDragEnter (evt) {
        const items = [...evt.dataTransfer.items];
        const canHandleFiles = items.filter(item => typeof item.getAsFile == 'function' && item.type !== 'text/plain').length > 0;
        const target = evt.target;
        const targetIsSorterItem = target.classList.contains('listing-photo-sorter__item');

        // Safari is the only browser that doesn't report the items (items.length == 0) at this stage.
        // So if we know we have items we check if we can handle them and if we don't, we must be dealing with Safari which definitely can handle them
        if ((canHandleFiles || items.length == 0) && !targetIsSorterItem) {
            this.dragTarget.classList.add(CLASSES.dragTargetShow);
        }
    }

    onDragExit () {
        this.dragTarget.classList.remove(CLASSES.dragTargetShow);
    }

    onDrop (evt) {
        const files = [...evt.dataTransfer.items].map(item => item.getAsFile());

        this.dragTarget.classList.remove(CLASSES.dragTargetShow);
        this.clearErrors();
        this.handleFiles(files);
    }

    handleFiles (fileList) {
        // Filter only the images from all the files
        const imageFiles = fileList.filter(file => {
            return file.type.substr(0, 6) === 'image/';
        });

        imageFiles.forEach((file) => {
            file.id = `file-${Math.random().toString().substr(2)}`;

            if (file.size === void 0) {
                this.addError(`Het bestand "${file.name}" is geen herkend bestandstype en kan niet verwerkt worden.`);
            } else if (file.size >= this.maxFileSize) {
                this.addError(`Het bestand "${file.name}" is te groot om te verwerken. De maximale bestandsgrootte is 5MB`);
            } else {
                this.getPreview(file, (canvas) => {
                    if (canvas instanceof HTMLImageElement) {
                        canvas = imgToCanvas(canvas);
                    }

                    this.messageBus.postMessage({
                        'message': MESSAGES.started,
                        'data': {
                            'img': canvas.toDataURL('image/jpeg', 1),
                            'id': file.id,
                        }
                    });
                });

                this.getBlobForTransfer(file, (blob) => {
                    this.transferFile(file, blob);
                });
            }
        });
    }

    getPreview (file, cb) {
        loadImage(file, (canvas) => {
            cb(canvas);
        }, {
            'orientation': true,
            'maxHeight': 200,
            'maxWidth': 300,
            'cover': true,
        });
    }

    getBlobForTransfer (file, cb) {
        loadImage(file, (canvas) => {
            if (typeof canvas.toBlob == 'function') {
                canvas.toBlob((imgBlob) => {
                    cb(imgBlob);
                }, 'image/jpeg', this.jpgQuality);

            } else if (typeof canvas.msToBlob == 'function') {
                const imgBlob = canvas.msToBlob();
                cb(imgBlob);
            } else {
                if (canvas instanceof HTMLImageElement) {
                    canvas = imgToCanvas(canvas);
                }

                const dataUrl = canvas.toDataURL('image/jpeg', this.jpgQuality);
                const http = new XMLHttpRequest();

                http.open('GET', dataUrl, true);
                http.responseType = 'blob';
                http.onload = function (e) {
                    if (this.status == 200 || this.status === 0) {
                        cb(this.response);
                    }
                };
                http.send();
            }
        }, {
            'orientation': true,
        });
    }

    transferFile (file, blob) {
        const formData = new FormData();
        formData.append('listing_photo[photo]', blob);
        formData.append('listing_photo[file_name]', file.name);

        fetch(this.endpoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            credentials: 'include',
        })
        .then(response => {
            if (response.status > 200) {
                this.messageBus.postMessage({
                    'message': MESSAGES.failed,
                    'data': {
                        'error': response.statusText,
                        'scope': file.id,
                    }
                });
            } else {
                response.json().then(json => {
                    this.messageBus.postMessage({
                        'message': MESSAGES.ended,
                        'data': {
                            'html': json.html,
                            'scope': file.id,
                            'photo_id': json.photo_id,
                        }
                    });
                })
            }
        })
        .catch(e => {
          console.error(e);
        });
    }

    addError (error) {
        this.errors.push(error);
        this.renderErrors();
    }

    renderErrors () {
        const errorsNode = findOne(SELECTORS.errors, this.element);
        const errorNodes = this.errors.map(error => `<li>${error}</li>`);

        errorsNode.innerHTML = errorNodes.join('\n');
    }

    clearErrors () {
        this.errors = [];
        this.renderErrors();
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': ListingPhotoUploader
};
