import Utils from 'utils';
import AsyncScriptLoader from 'utils/async-script-loader';

const PhotoGallery = (() => {
  const ATTRIBUTES = {
    'gallery': 'data-gallery',
    'container': 'data-gallery-container',
    'paginator': 'data-gallery-paginator',
  }
  const SELECTORS = {
    'gallery': `[${ATTRIBUTES.gallery}]`,
    'container': `[${ATTRIBUTES.container}]`,
    'paginator': `[${ATTRIBUTES.paginator}]`,
  }
  const SCRIPTS = {
    'gallery': HW.resources.gallery,
  }

  class PhotoGallery {
    constructor(options = {}) {
      this.container  = options.container || document.querySelector(SELECTORS.container);
      this.photos     = options.photos || [];
      this.links      = [];
      this.paginator  = null;

      this.init();
    }
    init() {
      if (this.photos.length === 0) {
        return;
      }

      this.paginator = document.querySelector(SELECTORS.paginator);

      new AsyncScriptLoader(SCRIPTS.gallery, () => {
        this.addListeners();
      }, true);
    }
    addListeners() {
      this.links = document.querySelectorAll(SELECTORS.gallery);
      Utils.forEach(this.links, (link) => {
        let index = link.getAttribute(ATTRIBUTES.gallery);
        link.addEventListener('click', () => {
          this.initGallery(index);
        });
      });
    }
    initGallery(index) {
      let self = this;

      blueimp.Gallery(this.photos, {
        index: {'href': index},
        container: this.container,
        onslide: function (index, slide) {
            let pos = this.getIndex() + 1;
            let count = this.getNumber();
            self.paginator.innerHTML = `${pos}/${count}`;
        },
      });
    }
  }

  return PhotoGallery;
})();

module.exports = PhotoGallery;
