import FormModal from 'utils/form-modal'

const RegisterModal = (() => {
  class RegisterModal extends FormModal {
    constructor(options = {}) {
      super(options)
    }
  }

  return RegisterModal
})()

export default RegisterModal
