import Utils from 'utils'
import * as Easing from 'helpers/easing/easing'

// inspired by http://stackoverflow.com/questions/8917921/cross-browser-javascript-not-jquery-scroll-to-top-animation

export const ScrollToTop = function(scrollTargetY = 0, speed = 1500, easing = 'easeInOutQuint') {
  let scrollY = Utils.getScrollY()
  let currentTime = 0

  const time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8))

  function tick() {
    currentTime += 1 / 60

    let p = currentTime / time
    let t = Easing[easing](p)

    if (p < 1) {
      requestAnimationFrame(tick)

      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
    } else {
      window.scrollTo(0, scrollTargetY)
    }
  }

  tick()
}

module.exports = ScrollToTop
