import Modal from 'modal';
import scrollToTop from 'utils/scroll-to-top';
import SearchClient from 'utils/search-client';
import Utils from 'utils';
import FormSelect from 'form/select';
import Collapse from 'collapse';

// TODO: on close modal, unset search results and url
const FilterModal = (() => {
  const CLASSES = {
    'hidden':             'hidden',
    'btnLoading':         'btn--loading',
  }
  const SELECTORS = {
    'form':               'modal-filter-form',
    'applyFilterBtn':     'apply-filter-button',
    'applySortBtn':       'apply-sort-button',
    'sortForm':           'sort-form-container',
    'filterForm':         'filter-form-container',
    'modalSortTrigger':   'modal-sort-trigger',
    'modalFilterTrigger': 'modal-filter-trigger',
  }

  class FilterModal {
    constructor(args = {}) {
      this.fetching       = false;
      this.firstInit      = true;

      this.url            = null;
      this.form           = null;
      this.sortForm       = null;
      this.filterForm     = null;
      this.applyBtn       = null;

      this.trigger        = args.trigger;
      this.modal          = args.modal;

      this.unbinders      = [];

      // classes
      this.searchClient   = null;

      this.initSearchClient();

      this.init();
    }
    init(args = {}) {
      if (args.trigger) {
        this.trigger = args.trigger;
      }
      this.form               = document.getElementById(SELECTORS.form);
      this.sortForm           = document.getElementById(SELECTORS.sortForm);
      this.filterForm         = document.getElementById(SELECTORS.filterForm);

      if (this.trigger.id == SELECTORS.modalSortTrigger) {
        Utils.removeClass(this.sortForm, CLASSES.hidden);
        this.applyBtn = document.getElementById(SELECTORS.applySortBtn);
      } else {
        Utils.removeClass(this.filterForm, CLASSES.hidden);
        this.applyBtn = document.getElementById(SELECTORS.applyFilterBtn);
      }

      this.initSearchClient();

      this.addListeners();
    }
    initSearchClient() {
      this.searchClient      = new SearchClient(this.render);
      this.searchClient.form = this.form;
      this.searchClient.path = this.isMapView ? HW.paths.listing_search_map : HW.paths.listing_search;
    }
    update = (e) => {
      this.fetching = true;
      Utils.addClass(this.applyBtn, CLASSES.btnLoading);
      this.searchClient.fetch(null, e);
    }
    render = (data, event, url) => {
      this.fetching = false;
      HW.app.events.trigger('search.set_results', {data: data, url: url});

      Utils.removeClass(this.applyBtn, CLASSES.btnLoading);

      this.modal.body = data.modal_filter;

      this.removeListeners();
      this.init();
    }
    ensureNotFetching() {
      let waitForFetchReady = (resolve) => {
        if (!this.fetching) {
          return resolve();
        }
        setTimeout(function() {
          waitForFetchReady(resolve);
        }, 30);
      }
      return new Promise(function(resolve, reject) {
        waitForFetchReady(resolve);
      });
    }
    apply = (e) => {
      e.preventDefault();
      e.stopPropagation();
      HW.app.events.trigger('search.show_loaders');
      HW.app.modal.close();
      this.ensureNotFetching().then(function() {
        HW.app.events.trigger('search.render');
        scrollToTop();
      });
    }
    addListeners() {
      this.form.addEventListener('change', this.update);
      this.applyBtn.addEventListener('click', this.apply);
      Utils.forEach(this.form.querySelectorAll(FormSelect.getSelector()), (el) => {
        let select = new FormSelect({
          el: el}
        );
        this.unbinders.push(select);
      })
      Utils.forEach(this.form.querySelectorAll(Collapse.getSelector()), (el) => {
        let collapse = new Collapse({
          el: el,
        })
        this.unbinders.push(collapse)
      })
      if (!this.firstInit) {
        this.modal.addContentListeners();
      }
      this.firstInit = false;
    }
    removeListeners() {
      this.form.removeEventListener('change', this.update);
      this.applyBtn.removeEventListener('click', this.apply);
      this.unbinders.map((unbinder) => {
        unbinder.dispose();
        this.unbinders = [];
      })
    }
    dispose(closeCalled) {
      this.removeListeners();
    }
  }

  return FilterModal;
})()

export default FilterModal;
