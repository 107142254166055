import keycode from 'keycode';
import { renderClasses } from 'helpers/bem/bem';
import { findOne, findAll } from 'helpers/dom/dom';
import { ComponentController } from 'helpers/stimulus/component-controller';

const NAME = 'accordion';

const CLASSES = {
    'button': renderClasses(NAME, 'button'),
};

const SELECTORS = {
    'button': `.${CLASSES.button}`,
};

class Accordion extends ComponentController {
    onKeyDown (evt) {
        const element = evt.target;
        const keypressed = keycode(evt.which);

        if (!['up', 'down', 'home', 'end'].includes(keypressed)) {
            return;
        }

        const buttons = findAll(SELECTORS.button, this.element);
        const selected = buttons.indexOf(element);
        let newIndex;

        switch (keypressed) {
            case 'up':
                newIndex = selected == 0 ? buttons.length - 1 : selected - 1;

                buttons[newIndex].focus();
                break;
            case 'down':
                newIndex = selected == buttons.length - 1 ? 0 : selected + 1;

                buttons[newIndex].focus();
                break;
            case 'home':
                buttons[0].focus();
                break;
            case 'end':
                buttons[buttons.length-1].focus();
                break;
        }

        evt.preventDefault();
    }

    onButtonClick (evt) {
        const button = evt.target;
        const id = button.getAttribute('aria-controls');

        if (button.getAttribute('aria-expanded') == 'false') {
            this.open(id);
        } else {
            this.close(id);
        }
    }

    open (id) {
        const button = findOne(`[aria-controls="${id}"]`, this.element);
        const content = findOne(`#${id}`, this.element);

        button.setAttribute('aria-expanded', true);
        content.removeAttribute('hidden');
    }

    close (id) {
        const button = findOne(`[aria-controls="${id}"]`, this.element);
        const content = findOne(`#${id}`, this.element);

        button.setAttribute('aria-expanded', false);
        content.setAttribute('hidden', '');
    }
}

export default {
    'name': NAME,
    'controller': Accordion,
};
