import Utils from 'utils'

const ListingCreateForm = (() => {
  const ATTRIBUTES = {
    'dataContactToggle' : 'data-contact-toggle',
  }
  const CLASSES = {
    private: 'listing-contact--private',
    realtor: 'listing-contact--realtor',
    contactContainer: 'listing-contact',
  }
  const SELECTORS = {
    contactContainer: `.${CLASSES.contactContainer}`,
    contactToggle: `[${ATTRIBUTES.dataContactToggle}]`,
  }
  const FORM_NAME = 'listing_create_form'

  class ListingCreateForm {
    constructor(options = {}) {
      this.unbinders = []

      this.toggleContactType = this.toggleContactType.bind(this);

      this.init()
    }

    addListeners() {
      HW.app.events.on('form:success', (data) => {
        if (data.name == FORM_NAME) {
          HW.app.events.off('form:success')
          this.dispose()
          this.init()
        }
      })
    }

    init() {
      this.addListeners()
      this.initContactToggle()
    }

    initContactToggle() {
      let container = document.querySelector(SELECTORS.contactContainer);

      if (container) {
        let choices = container.querySelectorAll(SELECTORS.contactToggle)
        Utils.forEach(choices, (el) => {
          if (el.checked) {
            this.toggleContactType(container, el);
          }

          let callback = () => {
            this.toggleContactType(container, el);
          }

          let unbinder = function () {
            el.removeEventListener('click', callback)
          }

          this.unbinders.push(unbinder)
          el.addEventListener('click', callback)
        })
      }
    }

    toggleContactType(container, el) {
      if (el.value == 'realtor') {
        Utils.addClass(container, CLASSES.realtor)
        Utils.removeClass(container, CLASSES.private)
      } else {
        Utils.addClass(container, CLASSES.private)
        Utils.removeClass(container, CLASSES.realtor)
      }
    }

    dispose() {
      this.unbinders.map(function (unbinder) {
        unbinder()
      })
      this.unbinders = []
    }
  }

  return ListingCreateForm
})()

module.exports = ListingCreateForm
