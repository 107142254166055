import { ComponentController } from 'helpers/stimulus/component-controller';
import { renderClasses } from 'helpers/bem/bem';
import { findOne } from 'helpers/dom/dom';

const NAME = 'dashboard-navigation';

const CLASSES = {
    'activeLink': renderClasses(NAME, 'link', ['active']),
};

const SELECTORS = {
    'activeLink': `.${CLASSES.activeLink}`,
};

class DashboardNavigation extends ComponentController {
    connect () {
        const activeLink = findOne(SELECTORS.activeLink, this.element);

        activeLink.scrollIntoView({ 'inline': 'end'});
    }
}

export default {
    name: NAME,
    controller: DashboardNavigation,
};
