import Utils from 'utils'

const Password = (() => {
  const CLASSES = {
    'addon': 'control-group__addon',
    'appended': 'control-group--appended',
    'hidePassword': 'control-group__addon--hide-password',
  }
  const LABELS = {
    'show': HW.i18n.password.show,
    'hide': HW.i18n.password.hide
  }

  class Password {
    constructor(options = {}) {
      this.el = options.el
      this.parentEl = options.parentEl
      this.button = null
      this.init()
    }
    init() {
      let fragment = document.createDocumentFragment()
      this.button = Utils.createEl('span', CLASSES.addon)
      this.button.setAttribute('title', LABELS.show)
      fragment.appendChild(this.button)
      this.parentEl.appendChild(fragment)
      Utils.addClass(this.parentEl, CLASSES.appended)

      this.button.addEventListener('click', this.toggle)
    }
    toggle = () => {
      if (this.el.type == 'password') {
        this.el.type = 'text'
        Utils.addClass(this.button, CLASSES.hidePassword)
        this.button.setAttribute('title', LABELS.hide)
      } else {
        this.el.type = 'password'
        Utils.removeClass(this.button, CLASSES.hidePassword)
        this.button.setAttribute('title', LABELS.show)
      }
    }
    dispose() {
      this.button.removeEventListener('click', this.toggle)
    }
  }

  return Password
})()

module.exports = Password
