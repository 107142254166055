import {ComponentController} from 'helpers/stimulus/component-controller';
import {renderClasses} from "helpers/bem/bem";

const NAME = 'loading-indicator';

const MESSAGE_HANDLERS = [
    'searchMapListingRenderStarted.all',
    'searchMapListingRenderEnded.all',
];

const CLASSES = {
    'loadingIndicator': renderClasses(NAME),
    'loadingIndicatorFadeIn': renderClasses(NAME, null, [ 'fadein' ]),
};

class LoadingIndicator extends ComponentController {

    show () {
        this.element.classList.add(CLASSES.loadingIndicatorFadeIn);
    }

    hide () {
        this.element.classList.remove(CLASSES.loadingIndicatorFadeIn);
    }

    onSearchMapListingRenderStarted () {
        this.show();
    }

    onSearchMapListingRenderEnded () {
        this.hide();
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': LoadingIndicator
};
