import MailCheck from 'mailcheck';

const MailChecker = (() => {
    const topLevelDomains = [ 'com', 'net', 'org', 'nl' ];
    const secondLevelDomains = [
        'hotmail', 'gmail', 'live', 'outlook', 'ziggo', 'msn',
        'home', 'kpnmail', 'upcmail', 'planet', 'hetnet', 'icloud',
        'live', 'chello', 'casema', 'xs4all', 'me', 'telfort',
        'online', 'kpnplanet', 'zeelandnet', 'quicknet', 'zonnet', 'tele2',
        'mail', 'versatel', 'caiway', 'yahoo'
    ];

    const completeEmailAddress = /^([a-z0-9_+-]+.)*[a-z0-9_+-]+@([a-z0-9-]+\.)+([a-z]{2,4}){1}$/i;

    class MailChecker {
        constructor (options) {
            this.el = options.el;
            this.check = this.check.bind(this);

            this.init();
        }

        init () {
            this.el.addEventListener('blur', this.check);
            this.el.addEventListener('keyup', this.check);
        }

        check = () => {
            const value = this.el.value;

            if (value.match(completeEmailAddress) !== null) {
                MailCheck.run({
                    email: this.el.value,
                    suggested: this.showSuggestion,
                    empty: this.clearSuggestion,
                    secondLevelDomains: secondLevelDomains,
                    topLevelDomains: topLevelDomains,
                });
            }
        };

        showSuggestion = (suggestionData) => {
            const parent = this.el.parentNode;
            let nextSibling;
            let suggestion;

            this.clearSuggestion();
            suggestion = this.buildSuggestion(suggestionData);

            nextSibling = this.el.nextSibling;

            if (nextSibling) {
                parent.insertBefore(suggestion, nextSibling);
            } else {
                parent.appendChild(suggestion);
            }
        };

        clearSuggestion = () => {
            const parent = this.el.parentNode;
            const suggestion = parent.querySelector('.mailcheck-suggestion');

            if (suggestion) {
                parent.removeChild(suggestion);
            }
        };

        buildSuggestion = (suggestionData) => {
            const suggestionText = decodeURIComponent(suggestionData.address) + '@<b>' + suggestionData.domain + '</b>';
            let container;
            let buttonHtml;
            let button;

            buttonHtml = [
                '<button type="button" ',
                'class="mailcheck-suggestion__accept"',
                'data-suggestion="' + decodeURIComponent(suggestionData.full) + '"',
                '>',
                suggestionText,
                '</button>'
            ].join('');

            container = document.createElement('div');
            container.className = 'mailcheck-suggestion';
            container.innerHTML = HW.i18n.mailcheck.suggestion.replace('%suggestion%', buttonHtml);

            button = container.querySelector('button');

            button.addEventListener('click', () => {
                const suggestion = button.getAttribute('data-suggestion');

                this.acceptSuggestion(suggestion);
            });

            return container;
        };

        static getSelector () {
            return '[data-mailchecker]';
        }

        acceptSuggestion = (value) => {
            this.el.value = value;

            this.clearSuggestion();
        }
    }

    return MailChecker;
})();

module.exports = MailChecker;
