import loadGoogleMaps from 'utils/google-maps-loader';
import MapMarkerData from 'utils/map-marker-data';

const InteractiveMap = (() => {
    const ATTRIBUTES = {
        'interactive_map': 'data-interactive-map',
        'static_map': 'data-static-map',
    };

    const CLASSES = {
        'visible': 'interactive-map--visible',
    };

    const SELECTORS = {
        'interactive_map': `[${ATTRIBUTES.interactive_map}]`,
        'static_map': `[${ATTRIBUTES.static_map}]`
    };

    let observer;

    if ('IntersectionObserver' in window) {
        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(CLASSES.visible);
                }
            });
        }, {
            rootMargin: '200px',
        })
    }

    class InteractiveMap {
        constructor(options) {
            this.el = options.el;
            this.unbinders = [];

            this.init();
        }

        init() {
            this.mapData = JSON.parse(this.el.getElementsByTagName('script')[0].textContent);

            this.addListeners();
        }

        addListeners() {
            Object.keys(SELECTORS).forEach(selector => {
                document.querySelector(SELECTORS[selector]).addEventListener('click', this.onMapButtonClicked);

                const unbinder = () => {
                    document.querySelector(SELECTORS[selector]).removeEventListener('click', this.onMapButtonClicked);
                };

                this.unbinders.push(unbinder);
            });

            if (observer) {
                const interactiveMap = this.el;
                observer.observe(interactiveMap);

                this.unbinders.push(() => {
                    observer.unobserve(interactiveMap);
                });
            } else {
                this.el.classList.add(CLASSES.visible);
            }
        }

        removeListeners() {
            this.unbinders.map(unbinder => {
                unbinder();
            });

            this.unbinders = [];
        }

        onMapButtonClicked = () => {
            loadGoogleMaps(this.buildMap);
        }

        buildMap = (google) => {
            let center = new google.maps.LatLng({
                'lat': this.mapData.lat,
                'lng': this.mapData.lng
            });
            let markerData = MapMarkerData.getForProperty()
            let map, marker;

            map = new google.maps.Map(this.el, {
                center: center,
                zoom: 14,
                scrollwheel: false,
            })

            marker = new google.maps.Marker({
                map: map,
                position: center,
                icon: markerData.image,
                shadow: markerData.shadow,
                shape: markerData.shape
            })

            this.removeListeners();
        }

        static getInteractiveMapSelector() {
            return SELECTORS.interactive_map;
        }
    }

    return InteractiveMap;
})();

module.exports = InteractiveMap;
