import Utils from 'utils';

const Tooltip = (() => {
  const ATTRIBUTES = {
    'tooltip': 'data-tooltip'
  }

  const CLASSES = {
    'tooltip': 'tooltip',
    'show': 'tooltip__content--show'
  };

  const SELECTORS = {
    'tooltip': `.${CLASSES.tooltip}`,
    'tooltipContent': `.${CLASSES.tooltip}__content`
  };

  const TOOLTIP_CONTENT_WIDTH = 192;

  class Tooltip {
    constructor(options={}) {
      this.el = options.el;
      this.revealOn = this.el.getAttribute(ATTRIBUTES.tooltip);
      this.content = this.el.querySelector(SELECTORS.tooltipContent);

      this.init();
    }
    init() {
      this.hide();
      this.setEventHandlers();
    }
    setEventHandlers() {
      if (this.revealOn == 'click' || Utils.isMobile()) {
        this.el.addEventListener('click', this.elementClick);
      } else if (this.revealOn == 'hover') {
        this.el.addEventListener('mouseover', this.show);
        this.el.addEventListener('mouseout', this.hide);
      }

      document.body.addEventListener('click', this.bodyClick);
    }
    elementClick = (e) => {
      if (!this.isShowing()) {
        let elementOffset = Utils.getOffset(this.el);
        let eventOffset = {top: e.clientY, left: e.clientX};
        let left = '';

        if (eventOffset.left < (TOOLTIP_CONTENT_WIDTH/2)) {
          left = TOOLTIP_CONTENT_WIDTH/2;
        } else if ((eventOffset.left + TOOLTIP_CONTENT_WIDTH) > window.innerWidth) {
          left = 16;
        } else {
          left = eventOffset.left - elementOffset.left;
        }

        this.content.style.top = `${eventOffset.top - elementOffset.top}px`;
        this.content.style.left = `${left}px`;
      } else {
        this.reset();
      }

      this.toggle();
    }
    bodyClick = (e) => {
      let target = e.target;

      if (target !== this.el && !Utils.closestParent(target, this.el)) {
        this.hide();
      }
    }
    isShowing() {
      return Utils.hasClass(this.content, CLASSES.show);
    }
    show = () => {
      Utils.addClass(this.content, CLASSES.show);
    }
    hide = () => {
      Utils.removeClass(this.content, CLASSES.show);
      this.reset()
    }
    toggle = () => {
      Utils.toggle(this.content, CLASSES.show);
    }
    reset = () => {
      setTimeout(() => {
        this.content.setAttribute('style', '');
      }, 300);
    }
    static getSelector() {
      return SELECTORS.tooltip;
    }
  }

  return Tooltip;
})();

module.exports = Tooltip;
