import { renderClasses } from '../../helpers/bem/bem';
import Utils from 'utils';

class Spinner {
    constructor () {
        const spinnerTemplateString = document.getElementById('spinner').innerHTML.trim();
        const textToNodes = document.createElement('div');
        textToNodes.innerHTML = spinnerTemplateString;
        this.element = textToNodes.firstChild;
        this.class = this.element.className;
    }

    fadeIn (callback) {
        Utils.addClass(this.element, renderClasses(this.class, null, ['fadein']));

        if (callback) {
            callback();
        }
    }

    remove (callback) {
        Utils.remove(this.element);

        if (callback) {
            callback();
        }
    }
}

export default Spinner;
