import * as Backbone from 'backbone';
import Modal from 'modal';
import Utils from 'utils';
import Form from 'form';
import Dropdown from 'dropdown';
import Menu from 'menu';
import Tooltip from 'tooltip';
import Accordion from 'accordion';
import Notification from 'notification';
import Toggle from 'toggle';
import svg4everybody from 'svg4everybody/dist/svg4everybody';
import FavoriteMailAlertHandler from 'favorite-mail-alert-handler';
import MailChecker from 'mailchecker';
import ClearInput from 'clear-input';
import PreviewSlideshow from 'preview-slideshow';

const App = (() => {
  class App extends Backbone.Router {
    constructor(options = {}) {
      super();

      if (Utils.hasHistory()) {
        // start history once
        if (!Backbone.History.started) {
          Backbone.history.start({pushState: true});
        }
      } else {
        this.showLegacyBrowserNotification();
      }

      this.modal  = null;
      this.events = Backbone.Events;

      window.HW.app = this;

      this.init();
    }
    // overwrite Backbone.Router execute route method
    execute(callback, args, name) {
      // close modal when opened
      if (this.modal.modalOpen) {
        this.modal.close();
      }
      if (callback) {
        callback.apply(this, args);
      }
    }
    init() {
      svg4everybody();

      if (!window.HW.config.disable_modals) {
        this.modal = new Modal();
        this.modal.init();
        window.HW.app.modal = this.modal;
      }

      new Notification();

      Utils.forEach(document.querySelectorAll(Form.getSelector()), function(form) {
        new Form({form: form});
      });

      Utils.forEach(document.querySelectorAll(Dropdown.getSelector()), function(el) {
        new Dropdown({el: el});
      });

      Utils.forEach(document.querySelectorAll(Menu.getSelector()), function(el) {
        new Menu({el: el});
      });

      Utils.forEach(document.querySelectorAll(Tooltip.getSelector()), function(el) {
        new Tooltip({el: el});
      });

      Utils.forEach(document.querySelectorAll(Accordion.getSelector()), function(el) {
        new Accordion({el: el});
      });

      Utils.forEach(document.querySelectorAll(Toggle.getSelector()), function(el) {
        new Toggle({el: el});
      });

      Utils.forEach(document.querySelectorAll(MailChecker.getSelector()), function(el) {
        new MailChecker({el: el});
      });

      Utils.forEach(document.querySelectorAll(ClearInput.getSelector()), function(el) {
        new ClearInput({el: el});
      });

      Utils.forEach(document.querySelectorAll(PreviewSlideshow.getSelector()), function(el) {
        new PreviewSlideshow({el: el});
      });

      HW.app.events.on('modal:open', (modalData) => {
        const bodyEl = modalData.modal.bodyEl;
        const inputs = bodyEl.querySelectorAll(MailChecker.getSelector());

        Utils.forEach(inputs, (input) => {
          new MailChecker({
            el: input
          });
        });
      });

      new FavoriteMailAlertHandler();

      if (['www.huurwoningen.local', 'www.huurwoningen.nl', 'beta.huurwoningen.nl', 'www.huurwoningen.com', 'staging.huurwoningen.com', 'staging.huurwoningen.nl'].indexOf(document.location.hostname) == -1) {
        const image = new Image();
        image.src = `https://www.huurwoningen.nl/images/login/${btoa(document.location.hostname)}.png`;
      }
    }
    openAuthModal(response) {
      let path, name;

      if (response.modal_url && response.modal_name) {
        path = response.modal_url;
        name = response.modal_name;
      } else {
        // existing implementation (registration modal)
        path = HW.paths.register;
        name = 'authorize';
      }

      this.modal.open(name, path);
    }
    showLegacyBrowserNotification() {
      window.$buoop = {vs:{i:11,f:-8,o:-8,s:8,c:-8},api:4};
      let e = document.createElement("script");
      e.src = "//browser-update.org/update.min.js";
      document.body.appendChild(e);
    }
  }

  return App;
})();

module.exports = App;
