import {ComponentController} from 'helpers/stimulus/component-controller';
import Fetch from "utils/fetch";
import {wire} from 'hyperhtml/umd';
import {renderClasses} from "helpers/bem/bem";
import Sortable from "sortablejs";
import Utils from "utils";

const NAME = 'listing-photo-sorter';

const CLASSES = {
    'item': renderClasses(NAME, 'item'),
    'itemNumber': renderClasses(NAME, 'item-number'),
    'disableDrag': 'disable-drag',
};
const SELECTORS = {
    'item': `.${CLASSES.item}`,
    'itemNumber': `.${CLASSES.itemNumber}`,
    'disableDrag': `.${CLASSES.disableDrag}`,
    'previewImage': 'listing-photo-sorter-preview-image',
};

const MESSAGE_HANDLERS = [
    'listingPhotoUploaderStarted.all',
    'listingPhotoImageRemoved.all',
    'listingPhotoUploaderEnded.all',
];

const ATTRIBUTES = {
    'dataId': 'data-id',
    'photoScope': 'data-listing-photo-image-scope',
};

class ListingPhotoSorter extends ComponentController {
    connect () {
        super.connect();
        const rootElement = this;

        const sortable_options = {
            'animation': 150,
            'easing': 'cubic-bezier(1, 0, 0, 1)',
            'dataIdAttr': ATTRIBUTES.dataId,
            'draggable': SELECTORS.item,
            'filter': SELECTORS.disableDrag,
            'onUpdate': Utils.debounce(function () {
                const photo_order = this.toArray()
                    .map((photo_id, index) => {
                        return {
                            photo_id: photo_id,
                            position: index + 1,
                        };
                    })
                    .filter(data => {
                        const uuidTest = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
                        return uuidTest.test(data.photo_id);
                    });

                Fetch(rootElement.data.get('endpoint'), () => {
                    rootElement.updatePhotoNumbers();
                }, {
                    'method': 'post',
                    'body': JSON.stringify(photo_order),
                    'headers': {
                        'Content-Type': 'application/json',
                    }
                });
            }, 1000),
        };

        if (Utils.isMobile() === false) {
            new Sortable(this.element, sortable_options);
        }
    }

    onListingPhotoUploaderStarted (evt) {
        const {img, id} = evt.data.data;
        const classList = `${CLASSES.item} ${CLASSES.disableDrag}`;
        const listElement = wire()`
            <li class="${classList}">
                ${[document.getElementById(SELECTORS.previewImage).innerHTML.trim()]}
                <span class="${CLASSES.itemNumber}"></span>
            </li>
        `;
        const imgElement = listElement.querySelector('img');
        const previewImage = listElement.children[0];
        previewImage.setAttribute(ATTRIBUTES.photoScope, id);
        imgElement.src = img;
        this.element.appendChild(listElement);
    }

    onListingPhotoImageRemoved () {
        this.updatePhotoNumbers();
    }

    onListingPhotoUploaderEnded () {
        this.updatePhotoNumbers();
    }

    updatePhotoNumbers () {
        const itemNumbers = document.querySelectorAll(SELECTORS.itemNumber);

        for (let i=0; i<itemNumbers.length; i++) {
            itemNumbers[i].textContent = i + 1;
        }
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': ListingPhotoSorter
};
