import Form from 'form';
import Utils from 'utils';

const FormModal = (() => {
  class FormModal {
    constructor(options = {}) {
      this.formClass = null;
      this.modal = options.modal;
      this.init(options);
    }
    init(options = {}) {
      this.modal.modalArgs['form_data'] = {
        'name': this.modal.modalName,
      };
      this.formClass = new Form({form: this.form});
      this.formClass.transformBody = this.transformBody;
      // form handler, this means the body of the modal is replaced
      HW.app.events.on('form:success', this._formHandler, this);
    }
    transformBody(body) {
      return body;
    }
    formHandler(data) {
      return data;
    }
    dispose() {
      HW.app.events.off('form:success', this._formHandler);
      this.formClass.dispose();
    }
    _formHandler = (data) => {
      this.modal.modalArgs['form_data'] = {
        'name': data.name,
      }
      this.formHandler(data);
      this.modal.addContentListeners(data);
    }
    get form() {
      return this.modal.body.querySelector(Form.getSelector());
    }
  }

  return FormModal;
})();

export default FormModal;
